import React, { useEffect, useState } from "react";
import PhoneIcon from "assets/images/phone-icon.svg";
import ExpertProfile from "assets/images/expert-profile.svg";
import ExpertInterviewed from "assets/images/expert-interviewed.svg";
import LikeIcon from "assets/images/like.svg";
import Image from "components/shared/Image";
import './AnalyticsCards.scss';
import PropTypes from "prop-types";

export const AnalyticsCards = ({ details, expertTypes }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (details) {
            setData(() => {
                const updatedData = [
                    {
                        name: 'expertProfileReceived',
                        type: 'Expert Profiles Received',
                        imgsrc: ExpertProfile,
                        count: 0
                    },
                    {
                        name: 'interviewsScheduled',
                        type: 'Interviews Scheduled',
                        imgsrc: PhoneIcon,
                        count: 0
                    },
                    {
                        name: 'expertsInterviewed',
                        type: 'Experts Interviewed',
                        imgsrc: ExpertInterviewed,
                        count: 0
                    },
                    {
                        name: 'interviewsCompleted',
                        type: 'Interviews Completed',
                        imgsrc: LikeIcon,
                        count: 0
                    }
                ];
                updatedData.forEach((item) => {
                    item.count = details[item.name]?.totalCount ? details[item.name].totalCount : 0;
                    item.expertTypeCount = details[item.name]?.expertTypeCount ? details[item.name].expertTypeCount : 0;
                });
                return updatedData;
            })
        }
    }, [details]);
    return (
        <div className="analyticscards__wrap">
            {
                data?.length > 0 ?
                    <div className="analyticscards__body analyticscards__body--data">
                        {
                            data?.map((option, index) =>
                                <div className="card__wrap" key={'ac'+index}>
                                    <div className="card__head">
                                        <div className="expert-img">
                                            <Image src={option?.imgsrc} alt="ExpertProfile" className="expert--icon" />
                                        </div>
                                        <div className="card__head--data">
                                            <div className="head--count">
                                                {option?.count}
                                            </div>
                                            <div className="head--name">
                                                {"Total " + option?.type}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card--divider"></div>
                                    <div className="card__body">
                                       <div className="body__wrap">
                                            {expertTypes && expertTypes.map((type) =>
                                                <div className="body--itemwrap" key={type.expertTypeName}>
                                                    <div className="body--item">
                                                        {type.expertTypeName}
                                                    </div>
                                                    {option.expertTypeCount &&
                                                        <div className="body--itemcount">
                                                            {option.expertTypeCount[type.expertTypeName]}
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div className="analyticscards__body analyticscards__body--nodata">
                        <div className="nocalls__data">
                            <div className="nocalls--head">
                                No analytics data available.
                            </div>
                            <div className="nocalls--text">
                                As you progress with your request and gather data, your analytics dashboard will populate here, offering insights into your activities.
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

AnalyticsCards.propTypes = {
    details: PropTypes.object.isRequired,
    expertTypes:PropTypes.array
  };