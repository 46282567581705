import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getChargeCodeDetailsAPIData } from "redux/actions/chargeCodeDetails/chargeCodeDetails.actions";
import { selectors as chargeCodeDetailsSelector } from 'redux/reducers/chargeCodeDetails/chargeCodeDetails_reducer';
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import Modal from "components/shared/Modal";
import CloseIcon from 'assets/images/close-popup.svg';
import Image from "components/shared/Image";
import './ChargeCodeDetails.scss';

export const ChargeCodeDetails = ({ chargeCode, showPopup, handleClosePopup }) => {
    const dispatch = useDispatch();
    const chargeCodeDetails = useSelector(chargeCodeDetailsSelector?.getChargeCodeDetails);
    const loadingSelector = useSelector(chargeCodeDetailsSelector?.getLoading);
    const [detailsData, setDetails] = useState({});
    const [openModel, setOpenModel] = useState(showPopup);

    useEffect(() => {
        fetchChargeCodeDetails();
    }, [chargeCode]);

    useEffect(()=>{
        setOpenModel(showPopup);
    },[showPopup]);

    useEffect(() => {
        setDetails(chargeCodeDetails);
    }, [chargeCodeDetails]);

    const fetchChargeCodeDetails = () => {
        dispatch(getChargeCodeDetailsAPIData(chargeCode));
    }

    return (
        <Modal isOpen={openModel} className="card-details-modal">
            <div className="model-wrapper">
            <div className="header model-header">
                <div className="details__header">
                    <div className="casecode-id">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.chargeCode || '-')}</div>
                    <div className="casecode-name">BCG Project Code & Utilization Category</div>
                </div>

                <Image src={CloseIcon} alt="closeIcon" className="close--details" onClick={() => { handleClosePopup() }} />
            </div>
            <div className="body model-body">
                <div className="body-wrapper">
                    <div className="row body-row">
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Charge Code</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.chargeCode || '-')}</div>
                        </div>
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Billing Officer Name</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.billingOfficerName || '-')}</div>
                        </div>
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Charge Code Office</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.chargeCodeOffice || '-')}</div>
                        </div>
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Industry Practice Area (IPA)</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.ipa || '-')}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Functional Practice Area (FPA)</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.fpa || '-')}</div>
                        </div>
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Request Type Flag</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.requestTypeFlag || '-')}</div>
                        </div>
                        <div className="body-sub-part">
                            <div className="div-sub-part-head">Closed Date</div>
                            <div className="div-sub-part-value">{loadingSelector === true ? <ImageParaSkeleton showPara={true} paraCount={1} size="small" height={4} /> : (detailsData?.closeDate || '-')}</div>
                        </div>
                        <div className="body-sub-part">
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </Modal>
    )
}
ChargeCodeDetails.propTypes={
    chargeCode: PropTypes.string.isRequired,
    showPopup: PropTypes.bool.isRequired,
    handleClosePopup: PropTypes.func.isRequired
}