import React, { useState } from "react";
import PropTypes from "prop-types";
import SlackIcon from "assets/images/slack-icon.svg";
import Image from "components/shared/Image";
import './ExternalNetworks.scss';

export const ExternalNetworks = ({ extdata }) => {
    return (
        <div className="externalNetworks__wrap">
            <div className="externalNetworks__headwrap">
                <div className="externalNetworks--head">
                    External Expert Networks
                </div>
                <div className="externalNetworks--count">
                    {extdata?.length}
                </div>
            </div>
            <div className="externalNetworks--subhead">
                You can chat with network by clicking on the card below
            </div>
            <div className="externalNetworks__body">
                <div className="row">
                    {
                        extdata?.map((item, index) =>
                            <div className="body__main" key={'ed'+index}>
                                <div className="body__wrap">
                                    <div className="body--item">
                                        <div className="body--itemText">
                                            {item?.name}</div>
                                    </div>
                                    <div className="body--img">
                                        <Image src={SlackIcon} alt="SlackIcon" className="slack--icon" />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

ExternalNetworks.propTypes={
    extdata: PropTypes.array.isRequired
}