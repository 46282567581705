import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { PROJECT_ANALYTICS_CODES } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getProjectAnalyticsCodesAPIDataPending,
    getProjectAnalyticsCodesAPIDataFailure,
    getProjectAnalyticsCodesAPIDataSuccess
} from "./projectAnalyticsCodes_actions";

import { setError } from '../error/error.actions';


export function* getProjectAnalyticsCodesAPIData(action) {
    try {
        const axiosConfig = {
            method: 'post',
            endpoint: CONFIG.API_URL.PROJECT_ANALYTICS_CODES,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json
            },
            params: action?.payload
        };
        yield put(getProjectAnalyticsCodesAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getProjectAnalyticsCodesAPIDataSuccess(response));
        }
        else {
            yield put(getProjectAnalyticsCodesAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getProjectAnalyticsCodesAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* projectAnalyticsCodesAPISaga() {
    try {
        yield takeLatest(PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_DATA, getProjectAnalyticsCodesAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}