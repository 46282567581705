import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { NOTIFICATIONS } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
    getNotificationsAPIDataFailure,
    getNotificationsAPIDataPending,
    getNotificationsAPIDataSuccess
} from "./notifications.actions";

import { setError } from '../error/error.actions';


export function* getNotificationsAPIData(action) {
    const { API_URL: { GET_NOTIFICATIONS } } = CONFIG;
    const paramURL = GET_NOTIFICATIONS(action?.payload);
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
        };
        yield put(getNotificationsAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getNotificationsAPIDataSuccess(response));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getNotificationsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* notificationsAPISaga() {
    try {
        yield takeLatest(NOTIFICATIONS.API_NOTIFICATIONS_DATA, getNotificationsAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}