import React from "react";
import { withRouter } from "react-router-dom";
import Image from "components/shared/Image";
import logoMinIcon from "assets/images/bcg-icon.png";
import "./AppFooter.scss";

const AppFooter = () => {

  return (
    <footer className="mainfooter__wrap">
      <div className="mainfooter__logo">
        <Image src={logoMinIcon} alt="logo" />
      </div>
      <div className="mainfooter__rightSection">
        © 2024 Boston Consulting Group
      </div>
    </footer>
  );
};

export default withRouter(AppFooter);
