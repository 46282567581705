import React, { useState, useRef, useEffect } from "react";
import Modal from "components/shared/Modal";
import moment from 'moment';
import CloseIcon from 'assets/images/close-popup.svg';
import CrossIcon from 'assets/images/close-black.svg';
import PlusIcon from 'assets/images/plus-icon.svg';
import DownloadIcon from 'assets/images/download-icon.svg';
import DollarIcon from 'assets/images/dollar.svg';
import RedDollarIcon from 'assets/images/red-dollar.svg';
import RecommendedIcon from 'assets/images/compare-rec.svg';
import ThreeDotsIcon from 'assets/images/three-dots.svg';
import ClockIcon from "assets/images/clock-icon-green.svg";
import StarIcon from "assets/images/star-outline.svg";
import MinusIcon from "assets/images/minus-outline.svg";
import FilledStarIcon from "assets/images/star.svg";
import { ReactComponent as CheckMarkImg } from "assets/images/checkmark.svg";
import Image from "components/shared/Image";
import { Table } from 'semantic-ui-react';
import './CompareProfiles.scss';
import { useDispatch, useSelector } from "react-redux";
import { setExpertProfileActionsAPIData } from "redux/actions/expertProfileActions/expertProfileActions.actions";
import { selectors as subProfileActionSelector } from "redux/reducers/expertProfileActions/expertProfileActions_reducer";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { ActionAlertWindow } from "components/shared/ActionAlertWindow";
import * as XLSX from 'xlsx';
import PropTypes from "prop-types";
import { SearchableField } from "components/shared/SearchableField";

export const CompareProfiles = ({ showPopup, popupType, queryData, isClosedRequest, selectedProfile, handleClosePopup, removeFromMainSelection, openScheduler, refreshProfileHandler }) => {
    const dispatchAction = useDispatch();
    const showAddMoreBlock = false;
    const tableDetails = [
        { name: "Expert Name", type: 'field' },
        { name: "Cost", type: 'field' },
        { name: "Status", type: 'field', column: 'profileStatus' },
        { name: "External Network", type: 'field', column: "networkProvider" },
        { name: "Relevant Work Experience", type: 'title' },
        { name: "Relevant Employer", type: 'field', column: "relevantEmployer" },
        { name: "Title", type: 'field', column: "relevantTitle" },
        { name: "Tenure", type: 'field', column: "relevantTenure" },
        { name: "Current Employer", type: 'field', column: "currentEmployer" },
        { name: "Title", type: 'field', column: "currentTitle" },
        { name: "Tenure", type: 'field', column: "currentTenure" },
        { name: "Expert Types/Modules", type: 'field' },
        { name: "Expert Highlights/Biography", type: 'field', column: 'biography' },
        { name: "Country", type: 'field', column: 'country' },
        { name: "Screening Question & Answer", type: 'title' }
    ];

    const profileActionsArray = [
        { name: 'Request Call', type: 'call', icon: ClockIcon, isDisabled: false },
        { name: 'Shortlist', type: 'shortlist', icon: StarIcon, isDisabled: false },
        { name: 'Shortlisted', type: 'unshortlist', icon: FilledStarIcon, isDisabled: false },
        { name: 'Decline', type: 'decline', icon: MinusIcon, isDisabled: false }
    ];

    const popupRef = useRef(null);

    const [headers, setHeaders] = useState(tableDetails);
    const [profilesData, setProfilesData] = useState([]);
    const [openCompareModel, setOpenCompareModel] = useState(showPopup);
    const [actionListOpen, setActionListOpen] = useState(-1);
    const [menuActionsMadeFlag, setmenuActionsMadeFlag] = useState(true);
    const [getShowDownload, setShowDownload] = useState({ open: false });
    const [visible, setVisible] = useState(false);
    const [showExpertListField, setShowExpertListField] = useState(false);
    const [declinedReason, setDeclinedReason] = useState('');

    const getProfileActionStatus = useSelector(subProfileActionSelector.getSaveProfileActionsResults);
    const getProfileActionError = useSelector(subProfileActionSelector.getErrorMessage);


    useEffect(() => {
        const handleMouseDown = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setActionListOpen(-1);
                setVisible(false);
            }
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    useEffect(() => {
        if (getProfileActionStatus?.message) {
            refreshProfileHandler();
            setmenuActionsMadeFlag(true);
        } else if (getProfileActionError) {
            toast.error(getProfileActionError);
        }
    }, [getProfileActionStatus, getProfileActionError]);

    useEffect(() => {
        if (selectedProfile?.length > 0) {
            const dataToSet = selectedProfile.map((profile) => {
                // Extract relevant experience details
                if (profile?.relevantExperience) {
                    const tenureAvailable = profile.relevantExperience.tenureYear > -1 && profile.relevantExperience.tenureMonth > -1;
                    const startDate = moment().set('month', profile.relevantExperience?.startMonth - 1 || '').format('MMM') + ' ' + profile?.relevantExperience?.startYear || '';
                    const endDate = profile.relevantExperience.employerStatus === "Current" ? 'Now' : moment().set('month', profile.relevantExperience?.endMonth - 1 || '').format('MMM') + ' ' + profile?.relevantExperience?.endYear || '';
                    const total = tenureAvailable ? `${profile.relevantExperience.tenureYear} yrs ${profile.relevantExperience.tenureMonth} mo` : '';

                    profile.relevantEmployer = profile?.relevantExperience.employerName;
                    profile.relevantTitle = profile?.relevantExperience.title;
                    profile.relevantTenure = total ? `${startDate} - ${endDate} (${total})` : `${startDate} - ${endDate}`;
                }

                // Extract current experience details
                if (profile?.relevantExperience?.employerStatus === "Current") {
                    profile.currentEmployer = profile.relevantEmployer;
                    profile.currentTitle = profile.relevantTitle;
                    profile.currentTenure = profile.relevantTenure;
                } else {
                    profile?.employmentHistory?.forEach((emp) => {
                        if (emp.currentEmployment) {
                            const startDate = moment().set('month', emp.startMonth - 1 || '').format('MMM') + ' ' + emp.startYear || '';
                            profile.currentEmployer = emp.employerName;
                            profile.currentTitle = emp.title;
                            profile.currentTenure = `${startDate} - Now`;
                        }
                    });
                }

                // Format screening questions
                if (profile?.screeningQuestions?.length > 0) {
                    const moreHeaders = profile.screeningQuestions.map((ques, index) => {
                        const quesCount = index + 1;
                        profile[`ques${quesCount}`] = ques.question;
                        profile[`ans${quesCount}`] = ques.answer;
                        return [
                            { name: `Question ${quesCount}`, type: 'field', column: `ques${quesCount}` },
                            { name: `Answer ${quesCount}`, type: 'field', column: `ans${quesCount}` },
                        ];
                    }).flat();

                    setHeaders((prevHeaders) => [...prevHeaders, ...moreHeaders]);
                }

                return profile;
            });

            setProfilesData(dataToSet);
        }
    }, [selectedProfile]);

    const handleRemoveFromPopup = (profileId) => {
        setProfilesData(() => {
            return profilesData.filter(item => item.profileId != profileId);
        });
        removeFromMainSelection(profileId, 'remove');
    }

    const handleProfileActions = (type) => {
        if (type === 'decline') {
            dispatchAction(setExpertProfileActionsAPIData({ "expertProfileIds": [actionListOpen], "action": type, "comment": declinedReason }));
            setVisible(false);
            setActionListOpen(-1);
            setmenuActionsMadeFlag(false);
            return;
        }
        if (type == 'call') {
            setOpenCompareModel(false);
            handleClosePopup();
            openScheduler(actionListOpen);
        } else {
            setActionListOpen(-1);
            setmenuActionsMadeFlag(false);
            dispatchAction(setExpertProfileActionsAPIData({ "expertProfileIds": [actionListOpen], "action": type }));
        }
        setVisible(false);
    };

    const handleCloseDownloadPopup = (flag) => {
        if (flag) {
            setShowDownload({ open: false });
        } else {
            //kirti: need to make common function from download functionality
            const excelData = [], dataToExport = profilesData;
            if (dataToExport?.length > 0) {

                dataToExport?.map((item, index) => {
                    const screeningResponses = item?.screeningQuestions?.map(sq =>
                        `Question: ${sq.question} Answer: ${sq.answer}`
                    ).join('\n');
                    excelData?.push({
                        'Expert Name': item?.expertName,
                        'Expert Type/Module': item?.expertTypes?.join(', '),
                        'Relevant Company': item?.relevantExperience?.employerName,
                        'Relevant Position': item?.relevantExperience?.title,
                        'Country': item?.country,
                        'Expert Highlights/Biography': item?.biography,
                        'Screening Responses': screeningResponses,
                        'Network': item?.networkProvider,
                        'Cost/hr': item?.rate?.costPerHour,
                        'Cost/30 min': item?.rate?.costPer30Mins,
                        'Recommended tag': item?.recommended,
                        'Status': item?.profileStatus,
                        'Shortlisted': item?.shortlisted
                    });
                });

                const workbook = XLSX.utils.book_new();

                // Convert data to worksheet
                const worksheet = XLSX.utils.json_to_sheet(excelData);

                // Add the worksheet to the workbook
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                // Save the workbook to an Excel file
                XLSX.writeFile(workbook, `${queryData} Expert Profiles.xlsx`);
                handleCloseDownloadPopup(true);
            }
        }
    }

    const addMoreExpProfileHandler = () => {
        setShowExpertListField(true);
    }
    const searchRequestHandler = (selection, type) => {
        if (!selection && !type) {
            setShowExpertListField(false);
            return;
        }

        switch (type) {
            case 'add':
                setProfilesData([...profilesData, selection]);
                removeFromMainSelection(selection.profileId, 'add');
                break;
            case 'remove':
                setProfilesData(profilesData.filter(selectedItem => selectedItem.id !== selection.id));
                removeFromMainSelection(selection.profileId, 'remove');
                break;
            default:
                console.warn(`Unhandled type: ${type}`);
        }
    }

    const renderActionOption = (option, item) => {
        switch (option.type) {
            case 'decline':
                return item.profileStatus === "Declined" ? (
                    <li className='disabled-action'>
                        <Image src={option.icon} className='edit--icon' alt="clock" />
                        Declined
                    </li>
                ) : (
                    <>
                        <li
                            onClick={() => !isClosedRequest && setVisible(!visible)}
                            className={isClosedRequest ? 'no--border disabled-action' : 'no--border'}
                        >
                            <Image src={option.icon} className='edit--icon' alt="clock" />
                            {option.name}
                        </li>
                        {visible && (
                            <div className='custom-input'>
                                <label htmlFor='customValue'>Reason for declining (Optional)</label>
                                <div className="decline--input">
                                    <input
                                        className='input-fields'
                                        type='text'
                                        id='customValue'
                                        placeholder='Reason for declining'
                                        onKeyUp={(e) => setDeclinedReason(e.target.value)}
                                    />
                                    <button
                                        className='other-button add-button'
                                        onClick={() => {
                                            handleProfileActions('decline');
                                            setDeclinedReason('');
                                            setVisible(false);
                                        }}
                                    >
                                        <CheckMarkImg />
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                );
            case 'unshortlist':
                return item.shortlisted && (
                    <li onClick={() => !isClosedRequest && handleProfileActions(option.type)} className={isClosedRequest ? 'disabled-action' : ''}>
                        <Image src={option.icon} className='edit--icon' alt="clock" />
                        {option.name}
                    </li>
                );
            case 'shortlist':
                return !item.shortlisted && (
                    <li onClick={() => !isClosedRequest && handleProfileActions(option.type)} className={isClosedRequest ? 'disabled-action' : ''}>
                        <Image src={option.icon} className='edit--icon' alt="clock" />
                        {option.name}
                    </li>
                );
            default:
                return (
                    <li
                        className={!item?.requestCallAllowed || isClosedRequest ? 'disabled-action' : ''}
                        onClick={() => !isClosedRequest && item?.requestCallAllowed && handleProfileActions(option.type)}
                    >
                        <Image src={option.icon} className='edit--icon' alt="clock" />
                        {option.name}
                    </li>
                );
        }
    }

    return (
        <>
            <Modal isOpen={openCompareModel} className="compare-profile-modal">
                <div className="header">
                    <div className="compare__header">
                        <div className="compare-name">
                            <span className="window-type">{popupType}</span> Expert Profiles
                        </div>
                    </div>
                    <div className="header__right">
                        {showAddMoreBlock &&
                        // popupType != 'duplicate' &&
                            <div className="add-experts">
                                {showExpertListField ?
                                    <div className="expert-search-field">
                                        <SearchableField
                                            fieldId="searchField"
                                            fieldType="expertName"
                                            preferredListHeight='95px'
                                            hideSearchIcon={false}
                                            isValid={true}
                                            isDisable={false}
                                            isMultiSelect={true}
                                            savedValue=''
                                            excludeData={profilesData}
                                            queryData={queryData}
                                            placeholder='Search for experts to compare'
                                            validateErrors={() => console.log('error')}
                                            onChargeCodeSelected={(value, field) => console.log(value, field)}
                                            handleSearchResults={(value, type) => searchRequestHandler(value, type)}
                                            isSurveyForm={false}
                                        />
                                    </div>
                                    : <div className="add-experts-btn" onClick={() => addMoreExpProfileHandler()}><Image src={PlusIcon} alt="PlusIcon" className="compare--details" />Add More Experts to Compare</div>
                                }
                            </div>
                        }
                        <div className="add-experts download--border" onClick={() => {
                            setShowDownload({
                                open: true,
                                details: 'By downloading this file, you agree and must ensure that the data is not stored on your computer and is permanently deleted from the case team folder within 90 days after the case is closed.',
                                cancelActionTxt: 'No, Cancel',
                                proceedActionTxt: 'Yes, Download',
                                noInputFields: true
                            });
                        }}> <Image src={DownloadIcon} alt="DownloadIcon" className="compare--details" />Download Profiles</div>

                        <Image src={CloseIcon} alt="closeIcon" className="compare--details" onClick={() => { setOpenCompareModel(false); handleClosePopup() }} />
                    </div>
                </div>
                <div className="table-container">
                    <Table celled className='calls--table'>
                        <Table.Body className='completedcalls__body'>
                            {headers.map((header) => (
                                <Table.Row key={header.column}>
                                    <Table.Cell className={header.type == 'title' ? 'heading-header-cell' : 'header-cell'}>{header.name}</Table.Cell>
                                    {profilesData.map((item) => (
                                        <Table.Cell key={'pd' + item?.id} className={header.type == 'field' && 'data-cell'}>
                                            {header.name == "Expert Name" ?
                                                <div className="exp--name">
                                                    <div className="exp--nameLeft">
                                                        {item?.expertName}
                                                        {item?.recommended && <div>
                                                            <Image src={RecommendedIcon} className='dollar--icon' alt="RecommendedIcon"
                                                                data-tooltip-id={'recom' + item?.id}
                                                                data-tooltip-content={'Network Recommended'}
                                                            />
                                                            <Tooltip id={'recom' + item?.id} place="bottom" effect="solid" className="expert--tooltip" />
                                                        </div>}
                                                        {item?.shortlisted && <div>
                                                            <Image src={FilledStarIcon} alt="Star Icon" className="badge--icon"
                                                                data-tooltip-id={'star' + item?.id}
                                                                data-tooltip-content={'Shortlisted'}
                                                            />
                                                            <Tooltip id={'star' + item?.id} place="bottom" effect="solid" className="expert--tooltip" />
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="exp--nameRight">
                                                        <div className='action-menu-div'>
                                                            {
                                                                actionListOpen == item?.id &&
                                                                <div className="sort-popup" ref={popupRef}>
                                                                    <ul>
                                                                        {profileActionsArray?.map((option, popupIndex) => (
                                                                            <React.Fragment key={popupIndex}>
                                                                                {renderActionOption(option, item, popupIndex)}
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                        <Image src={ThreeDotsIcon} className='dollar--icon' alt="ThreeDotsIcon"
                                                            onClick={(event) => {
                                                                if (actionListOpen == item?.id) {
                                                                    setActionListOpen(-1);
                                                                } else {
                                                                    setActionListOpen(item?.id);
                                                                }
                                                            }} />
                                                        {popupType != 'duplicate' && <Image src={CrossIcon} className='dollar--icon' alt="CloseIcon" onClick={() => { handleRemoveFromPopup(item.profileId) }} />}
                                                    </div>
                                                </div>
                                                : header.name == "Cost" ?
                                                    <div className={`"cost--container" ${item.rate.rateType.includes('Premium') && "red--color"}`}><Image src={item.rate.rateType.includes('Premium') ? RedDollarIcon : DollarIcon} className='dollar--icon' alt="DollarIcon" />{item.rate.costPerHour}/hr, {item.rate.costPer30Mins}/30 min ({item.rate.rateType})</div>
                                                    : header.column ?
                                                        item[header.column]
                                                        : header.name == "Expert Types/Modules" ?
                                                            item?.expertTypes?.join(', ')
                                                            : null
                                            }
                                        </Table.Cell>
                                    ))}

                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Modal >
            {
                getShowDownload.open &&
                <ActionAlertWindow props={getShowDownload} closeAlertWindowHandler={(flag) => handleCloseDownloadPopup(flag)} >
                </ActionAlertWindow>
                // <DownloadProfiles handleCloseDownloadPopup={handleCloseDownloadPopup} openModel={getShowDownload} downloadExpertsResults={profilesData} />
            }

        </>
    );
}

CompareProfiles.propTypes = {
    showPopup: PropTypes.bool.isRequired,
    popupType: PropTypes.string.isRequired,
    queryData: PropTypes.string.isRequired,
    selectedProfile: PropTypes.array.isRequired,
    handleClosePopup: PropTypes.func.isRequired,
    removeFromMainSelection: PropTypes.func.isRequired,
    openScheduler: PropTypes.func.isRequired,
    refreshProfileHandler: PropTypes.func.isRequired,
}