import { NO_ACCESS } from "redux/constants";
export const name = "noAccess";

// initial state
export const initialState = {
    loading: false,
    error: false,
    errorMessage: "",
    noAccessResults: "",
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getNoAccessResultsResults: (state) => state[name]?.noAccessResults ? state[name]?.noAccessResults : "",
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case NO_ACCESS.API_NO_ACCESS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action?.payload
            };
        case NO_ACCESS.API_NO_ACCESS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: ''
            };
        case NO_ACCESS.API_NO_ACCESS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.length <= 0,
                noAccessResults: action?.payload?.message
            };
        case NO_ACCESS.API_CLEAR_NO_ACCESS:
            return {
                ...state,
                loading: false,
                noAccessResults: "",
            }
        default:
            return state;
    }
}