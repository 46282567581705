import { PROJECT_ANALYTICS } from "redux/constants";
export const name = "projectAnalytics";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    projectAnalyticsData: null,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getProjectAnalyticsData: (state) => state[name]?.projectAnalyticsData ? state[name]?.projectAnalyticsData : [],
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            };
        case PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: '',
            };
        case PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                projectAnalyticsData: action?.payload?.data?.chargeCodes,
                errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
        case PROJECT_ANALYTICS.CLEAR_PROJECT_ANALYTICS:
            return {
                ...state,
                projectAnalyticsData: [],
            }

        case PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_RESULTS_RESET:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
                projectAnalyticsData: [],
            }
        default:
            return state;
    }
}