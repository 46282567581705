import { NOTIFICATIONS } from 'redux/constants';

//  People CCO actions
export const getNotificationsAPIData = (hrid) => ({
    type: NOTIFICATIONS.API_NOTIFICATIONS_DATA,
    payload: hrid
});

export const getNotificationsAPIDataPending = () => ({
    type: NOTIFICATIONS.API_NOTIFICATIONS_RESULTS_PENDING
});

export const getNotificationsAPIDataSuccess = (payload) => ({
    type: NOTIFICATIONS.API_NOTIFICATIONS_RESULTS_SUCCESS,
    payload
});

export const getNotificationsAPIDataFailure = (error) => ({
    type: NOTIFICATIONS.API_NOTIFICATIONS_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});
