import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { PEOPLE_DATA } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";

import * as utils from '@kdshared/okta-utils';
import { moduleFailure } from "redux/global.action.js";
// Actions
import {
  getPeopleAPIDataPending,
  getPeopleAPIDataSuccess,
  getPeopleAPIDataFailure
} from "./peopleresults_actions";

import { setError } from '../error/error.actions';

const getQueryData = (hrid) => {
   // need to verify after 14 march
    // email-workEmail
    // firstName – Consider using preferredFirstName.
    // lastName – Consider using preferredLastName.
    // 1.   maidenName – Workday does not support maiden name (no alternative).
    // 2.   globalTitle – Consider using businessTitle.
    // 3.   homeOfficeRegion – Not supported in Workday.
    // 4.   jobFunction - Consider using jobFamilyGroup.
    // 5.   mobile – Use phones.phoneNumber where the device type equals landline.
    // 6.   phone – Use phones.phoneNumber where the device type equals landline.
    // 7.   nationality – Consider using nationalities.
    // 8.   responsibilityCenters – Consider using costAllocations.
    // 9.   lastUpdated – Deprecated.
    // 10. staffId – Consider using Id.
    // 11. location – Consider using hostOfficeId.
  return `query {
    employee(id: "${hrid}") {
        id
        preferredName
        firstName
        lastName
        picture
        email
        jobTitle
        homeOffice
        businessTitle
        businessTitle
        hostOfficeLocation {
          id
          name
          city
          country
          countryCode
          timezone
      }
  phones{
          phoneNumber
          deviceType
          countryCode
          usage
      }
    }
}
`
}

const getMultipleQueryData = (hrid) => {
  const idList = hrid ? hrid.map(id => `"${id}"`).join(', ') : '';
  return `query {
    employees(ids: [${idList}]) {
        id
        preferredName
        firstName
        lastName
        picture
        email
        homeOffice
        businessTitle
        jobTitle
        hostOfficeLocation {
          id
          name
          city
          country
          countryCode
          timezone
      }
  phones{
          phoneNumber
          deviceType
          countryCode
          usage
      }
    }
}
`
}

export function* getPeopleAPIData(hrid) {
  try {
    const axiosConfig = {
      method: 'POST',
      endpoint: CONFIG.API_URL.GET_PEOPLE,
      headers: {
        'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
        psid: utils.getPsId(),
        'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
      },
      params: {
        query: typeof (hrid?.payload) === 'string' ? getQueryData(hrid?.payload) : getMultipleQueryData(hrid?.payload),
      },
    };
    yield put(getPeopleAPIDataPending());
    const response = yield call(doAxiosRequest, axiosConfig);

    if (response) {
      if (typeof (hrid?.payload) === 'string') {
        yield put(getPeopleAPIDataSuccess(response.data.employee));
      }
      else {
        yield put(getPeopleAPIDataSuccess(response.data.employees));
      }
    }
    else {
      yield put(getPeopleAPIDataFailure(CONFIG.ERROR_MESSAGES.NOT_FOUND));
    }
  }
  catch (err) {
    // yield (put(setError(err)));
    yield put(getPeopleAPIDataFailure(CONFIG.ERROR_MESSAGES.NOT_FOUND));
  }
}


export default function* peopleResultsSaga() {
  yield takeEvery(PEOPLE_DATA.API_PEOPLE_DATA, getPeopleAPIData);
  // try {
  //   yield takeEvery(PEOPLE_DATA.API_PEOPLE_DATA, getPeopleAPIData);

  // }
  //  catch (error) {
  //   yield (put(setError(error)));
  //   yield put(moduleFailure(error, 'peopleResultsSaga'));
  // }
}