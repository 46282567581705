import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import PendingProfileIcon from "assets/images/expertprofile-pending.svg";
import Image from "components/shared/Image";
import { RequestMembers } from 'components/RequestMembers';
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import { formatDate } from "utils/formatDate/formatDate";
import 'react-loading-skeleton/dist/skeleton.css';
import { NewUser } from "components/NewUser";
import { Banner } from "components/Banner";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getRandomColor } from 'utils/helpers/helpers';
import { getUserId } from "utils/auth/auth";
import { getStaffId, initiateInterviewReqCardClickAnalytics } from '../../analytics.js';
import './OpenRequests.scss';


export const OpenRequests = ({ requestData, isOpenRequestFlag, hasMoreData, loadMoreData, existingUser, isConsentVisible, noRecordFoundFlag, loadingFlag }) => {
    const [loading, setLoading] = useState(loadingFlag);
    const [noRecordFound, setnoRecordFound] = useState(noRecordFoundFlag);
    const [requestorData, setRequestorData] = useState(new Array(requestData.length).fill(null));
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setLoading(loadingFlag);
    }, [loadingFlag]);

    const handleAnalyticsType = (type) => {
        switch (type) {
            case 'expertProfileReceived': return 'Expert Profile received';
            case 'interviewsScheduled': return 'Interview Scheduled';
            case 'expertsInterviewed': return 'Experts Interviewed';
            case 'interviewsCompleted': return 'Interviews Completed'
        }
    }

    const handleRequestor = async (caseTeamData) => {
        if (!caseTeamData) return [{ profile: '', name: '' }];
        const requestor = caseTeamData.find(member => member?.memberType === "requestor");
        const hrid = requestor ? [requestor?.hrId] : null;
        if (hrid) {
            const resp = await getUserProfilePictures(hrid);
            let profilePic = resp?.[0]?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : resp?.[0]?.picture;

            if (resp)
                return [{
                    profile: profilePic,
                    name: resp[0]?.firstName?.concat(' ').concat(resp[0]?.lastName) || '',
                    nameInitials: resp[0]?.firstName?.charAt(0) + resp[0]?.lastName?.charAt(0) || '',
                    bgColor: getRandomColor()
                }];
        }
        return [{ profile: '', name: '', nameInitials: '', bgColor: '' }];
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        const hrid = await getUserId();
        const resp = await getUserProfilePictures([hrid]);
        if (resp?.length > 0) {
            getStaffId(resp[0]?.email);
        }
    }

    useEffect(() => {
        setnoRecordFound(noRecordFoundFlag);
    }, [noRecordFoundFlag])

    useEffect(() => {
        const fetchRequestorData = async () => {
            const data = await Promise.all(requestData.map(item => handleRequestor(item?.caseTeamMembers)));
            setRequestorData(data);
        };
        fetchRequestorData();
    }, [requestData]);

    useEffect(() => {
        setShowBanner(!existingUser);
    }, [existingUser]);

    return (
        <div className='openrequests__wrap'>
            {
                (loading) ?
                    <div className='data__loading'>
                        <ImageParaSkeleton showPara={true} showImage={true} imageCount={1} paraCount={5} size="extraLarge" />
                    </div>
                    :
                    <>
                        {!noRecordFound ?
                            (requestData.length > 0 ?
                                <InfiniteScroll dataLength={requestData.length} next={loadMoreData} hasMore={hasMoreData}>
                                    {
                                        requestData?.map((item, index) =>
                                            <div className='openrequests__card' id={'openrequests__card' + index} key={item?.interviewRequestId} onClick={() => {
                                                initiateInterviewReqCardClickAnalytics('EEH Request Details Page', item?.interviewRequestId, 'EEH Home Page');
                                                sessionStorage.clear('hasLoaded');
                                                window.location.href = `/requestDetails/details?interviewId=${item?.interviewRequestId}`;
                                                // history.push(`/requestDetails?interviewId=${item?.interviewRequestId}`)
                                            }
                                            }
                                            >
                                                <div className='openrequests__titlewrap'>
                                                    <div className='openrequests__cardTitle'>
                                                        <div className='openrequests__cardLeft'>
                                                            <div className='request--id'>Interview Request - ID {item?.interviewRequestId} | </div>
                                                            <div className='charge--code'>Charge Code : {item?.chargeCode ? `${item?.chargeCode}` : '-'} </div>
                                                        </div>
                                                        <div className='reqBy__onWrap'>
                                                            <div className='requested--date'>
                                                                Requested by :
                                                                {requestorData[index] && requestorData[index]?.length > 0 && (
                                                                    <>
                                                                        {requestorData[index][0]?.profile ?
                                                                            <Image src={requestorData[index][0]?.profile} alt="SampleUser" className='reqby--img' /> :
                                                                            <span
                                                                                style={{ background: requestorData[index][0]?.bgColor }}
                                                                                className={`requested--img user--img custom--profile--pic`}>
                                                                                {requestorData[index][0]?.nameInitials?.length > 0 ? requestorData[index][0]?.nameInitials : 'N/A'}
                                                                            </span>
                                                                        }

                                                                        {requestorData[index][0]?.name}&nbsp;
                                                                    </>
                                                                )}
                                                                |
                                                            </div>
                                                            <div className='requested--date'>
                                                                {isOpenRequestFlag ? 'Requested' : 'Closed'} on : {isOpenRequestFlag ? formatDate(item?.requestCreationDate) : formatDate(item?.requestClosureDate)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='request--title'>{item?.requestTitle}</div>
                                                </div>
                                                <div className='openrequests__cardBody'>
                                                    <div className='cardBody__team'>
                                                        {
                                                            item?.caseTeamMembers &&
                                                            <div className='team--title'>TEAM</div>
                                                        }
                                                        <RequestMembers userdata={item?.caseTeamMembers} />
                                                    </div>
                                                    <div className='cardBody__analytics'>
                                                        {
                                                            item?.requestAnalytics?.length > 0 &&
                                                            <div className='analytics--title'>Request Analytics</div>
                                                        }
                                                        <div className='analytics__data'>
                                                            {
                                                                item?.requestAnalytics?.length > 0
                                                                    ?
                                                                    <>
                                                                        {
                                                                            item?.requestAnalytics?.map((data, index) =>
                                                                                <div className='expProfile__data' key={'a'+index}>
                                                                                    <div className='analytics--count'>{data?.count}</div>
                                                                                    <div className='analytics--head'>{handleAnalyticsType(data?.type)}</div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :
                                                                    <div className='analytics__pending'>
                                                                        <Image src={PendingProfileIcon} alt="PendingProfileIcon" className='pending--icon' />
                                                                        <div className='pending--data'>You will begin receiving expert profiles shortly.</div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </InfiniteScroll>
                                :
                                <div className='data__loading'>
                                    <ImageParaSkeleton showPara={true} showImage={true} imageCount={1} paraCount={5} size="extraLarge" />
                                </div>
                            )
                            :
                            (showBanner && noRecordFound &&
                                <div className='no--recordFound'>
                                    <Banner
                                        bannerText={isOpenRequestFlag ? "Ready to Take on New Projects?" : "Looking to Recap Completed Initiatives?"}
                                        bannerSubText={isOpenRequestFlag ? "Currently, there are no open requests. Explore new opportunities by connecting with external experts. Start your next case today to leverage their unique expertise and experience." : "Currently, there are no closed requests. As you complete and archive projects, they will appear here, allowing you to review and reflect on your past work."}
                                    />
                                    {!isConsentVisible && <NewUser />}
                                </div>
                            )

                        }
                    </>
            }
        </div>
    )
}

OpenRequests.propTypes={
    existingUser: PropTypes.bool.isRequired,
    loadingFlag: PropTypes.bool.isRequired,
    requestData: PropTypes.array.isRequired,
    isOpenRequestFlag: PropTypes.bool.isRequired,
    hasMoreData: PropTypes.bool.isRequired,
    loadMoreData: PropTypes.func.isRequired,

}