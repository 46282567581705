
import { doAxiosRequest } from "config-axios";
import CONFIG from "config";
import * as utils from '@kdshared/okta-utils';

export const getUserProfilePictures = async (hrid) => {
    try {
        const axiosConfig = {
            method: 'POST',
            endpoint: CONFIG.API_URL.GET_PEOPLE,
            headers: {
                'x-api-key': CONFIG.PEOPLE_API_X_API_KEY,
                psid: utils.getPsId(),
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
            params: {
                query: getMultipleQueryData(hrid),
            },
        };
        const response = await doAxiosRequest(axiosConfig);
        if (response) {
            return response?.data?.employees;
        }
        else {
            return []
        }
    }
    catch (err) {
        return []
    }
}

const getMultipleQueryData = (hrid) => {
    const idList = hrid.map(id => `"${id}"`).join(', ');
    // need to verify after 14 march
    // email-workEmail
    // firstName – Consider using preferredFirstName.
    // lastName – Consider using preferredLastName.
    // 1.   maidenName – Workday does not support maiden name (no alternative).
    // 2.   globalTitle – Consider using businessTitle.
    // 3.   homeOfficeRegion – Not supported in Workday.
    // 4.   jobFunction - Consider using jobFamilyGroup.
    // 5.   mobile – Use phones.phoneNumber where the device type equals landline.
    // 6.   phone – Use phones.phoneNumber where the device type equals landline.
    // 7.   nationality – Consider using nationalities.
    // 8.   responsibilityCenters – Consider using costAllocations.
    // 9.   lastUpdated – Deprecated.
    // 10. staffId – Consider using Id.
    // 11. location – Consider using hostOfficeId.


    return `query {
      employees(ids: [${idList}]) {
          id
        preferredName
        firstName
        lastName
        picture
        email
        homeOffice
        businessTitle
        jobTitle
        hostOfficeLocation {
          id
          name
          city
          country
          countryCode
          timezone
      }
  phones{
          phoneNumber
          deviceType
          countryCode
          usage
      }
      }
  }
  `
}