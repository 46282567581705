import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import CONFIG from "config";
import { CLOSE_REQUEST } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getCloseRequestAPIDataPending,
    getCloseRequestAPIDataFailure,
    getCloseRequestAPIDataSuccess
} from "./closeRequest.actions";

import { setError } from '../error/error.actions';


export function* getCloseRequestAPIData(action) {
    try {
        const { API_URL: { CLOSE_INTERVIEW_REQUEST } } = CONFIG;
        let paramURL = CLOSE_INTERVIEW_REQUEST(action?.payload?.requestId , action?.payload?.chargeCode);
            const axiosConfig = {
                method: 'PUT',
                endpoint: paramURL,
                headers: {
                    'x-api-key': CONFIG.BE_X_API_KEY,
                    'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
                },
            };
            yield put(getCloseRequestAPIDataPending());
            const response = yield call(doAxiosRequest, axiosConfig);
            if (response && response.data) {
                yield put(getCloseRequestAPIDataSuccess(response.data));
            } else {
                yield put(getCloseRequestAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
            }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getCloseRequestAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* closeRequestAPISaga() {
    try {
        yield takeEvery(CLOSE_REQUEST.API_CLOSE_REQUEST_DATA, getCloseRequestAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}