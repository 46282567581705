import React, { useEffect, useRef, useState } from "react";
import { getNotificationsAPIData } from 'redux/actions/notifications/notifications.actions';
import { selectors as notificationsSelector } from '../../redux/reducers/notifications/notifications_reducer';
import { useDispatch, useSelector } from "react-redux";
import { getUserId } from "utils/auth/auth";
import PropTypes from "prop-types";
import { Image } from 'semantic-ui-react';
import { ImageParaSkeleton } from 'components/shared/ImageParaSkeleton';
import { formatDate } from "utils/formatDate/formatDate";
import SettingsIcon from 'assets/images/settings.svg';
import ClearIcon from 'assets/images/clear.svg';
import CrossIcon from 'assets/images/noti-close.svg';
import './Notifications.scss';
import moment from 'moment';

export const Notifications = ({ noticount }) => {
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const getNotiAPIData = useSelector(notificationsSelector.getNotifcationsDetails);
  const loadingData = useSelector(notificationsSelector?.getLoading);
  const [getNotiData, setNotiData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNotiData();
  }, []);

  const fetchNotiData = async () => {
    const hrid = await getUserId();
    dispatch(getNotificationsAPIData(hrid));
  }

  useEffect(() => {
    setNotiData(getNotiAPIData);
    noticount(getNotiAPIData?.length);

  }, [getNotiAPIData]);

  useEffect(() => {
    setLoading(loadingData);
  }, [loadingData]);

  return (
    <div className="Notifications__wrap" ref={notificationsRef}>
      <div className='notihead__wrap'>
        <div className="noti--head">Notifications</div>
        <div className='noti--headRight'>
          <Image className="settings-icon" src={SettingsIcon} alt="SettingsIcon" />
          <Image className="settings-icon" src={ClearIcon} alt="ClearIcon" />
        </div>
      </div>

      <div className="noti__body">
        <>
          {
            getNotiData?.length === 0 && loading === false ?
              <div className='no--noti'>
                No notifications found.
              </div>
              :
              <>{
                getNotiData?.length === 0 && loading === true ?
                  <ImageParaSkeleton showPara={true} paraCount={5} size="430Width" height={4} />
                  :
                  <div className="noti__itemWrap">
                    {
                      getNotiData?.map((option, index) => (
                        <div key={'noti' + index} className={'noti__item'}>
                          <div className="noti__itemSub">
                            <div className="noti--text">{option?.notification}</div>
                            <div className="noti--id"><Image className="settings-icon" src={CrossIcon} alt="CrossIcon" />
                            </div>
                          </div>
                          <div className="noti--notiTime">{formatDate(option?.receivedTimestamp)}</div>
                        </div>
                      ))
                    }
                  </div>
              }
              </>
          }
        </>
      </div>
    </div>
  );
};

Notifications.propTypes = {
  closePopup: PropTypes.func.isRequired,
  notiData: PropTypes.array.isRequired
}
