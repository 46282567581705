import React, { useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './ImageParaSkeleton.scss';

export const ImageParaSkeleton = ({ showImage = false, showPara = false, paraCount = 0, imageCount = 0, size = 'medium',height="4" }) => {
    const handleLineSize = () => {
        switch (size) {
            case 'extraSmall': return 20;
            case 'small': return 80;
            case 'mediumsmall': return 190;
            case 'medium': return 380;
            case 'large': return 700;
            case 'extralarge': return 1000;
            case 'fullWidth' : return '100%';
            case '430Width' : return 430;
        }
    }

    return (
        <div className="skeleton__wrap">
            <SkeletonTheme color="#e0e0e0" highlightColor="#B1B1B1">
                <div className="user__skeleton">
                    <>
                        {
                            showImage &&
                            <div className="user-skeleton__left">
                                <Skeleton circle={true} height={48} width={48} count={imageCount} />
                            </div>
                        }
                    </>
                    <>
                        {
                            showPara &&
                            <div className="user-skeleton__right">
                                <Skeleton count={paraCount} height={height} width={handleLineSize()} />
                            </div>
                        }
                    </>
                </div>
            </SkeletonTheme>
        </div>
    )
}