import { CLOSE_REQUEST } from 'redux/constants';

//  People CCO actions
export const getCloseRequestAPIData = (data) => ({
    type: CLOSE_REQUEST.API_CLOSE_REQUEST_DATA,
    payload: data
});

export const getCloseRequestAPIDataPending = () => ({
    type: CLOSE_REQUEST.API_CLOSE_REQUEST_RESULTS_PENDING
});

export const getCloseRequestAPIDataSuccess = (payload) => ({
    type: CLOSE_REQUEST.API_CLOSE_REQUEST_RESULTS_SUCCESS,
    payload
});

export const getCloseRequestAPIDataFailure = (error) => ({
    type: CLOSE_REQUEST.API_CLOSE_REQUEST_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});