import { put, call, takeLatest } from 'redux-saga/effects';
// Config
import CONFIG from "config";
import { PROJECT_ANALYTICS } from "redux/constants";
// Axios Util
import { doAxiosRequest } from "config-axios";
// Actions
import {
    getProjectAnalyticsAPIDataPending,
    getProjectAnalyticsAPIDataFailure,
    getProjectAnalyticsAPIDataSuccess
} from "./ProjectAnalytics_actions";

import { setError } from '../error/error.actions';


export function* getProjectAnalyticsAPIData(action) {
    try {
        const axiosConfig = {
            method: 'GET',
            endpoint: CONFIG.API_URL.PROJECT_ANALYTICS(action?.payload),
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json
            },
        };
        yield put(getProjectAnalyticsAPIDataPending());
        const response = yield call(doAxiosRequest, axiosConfig);
        if (response) {
            yield put(getProjectAnalyticsAPIDataSuccess(response));
        }
        else {
            yield put(getProjectAnalyticsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
        }
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getProjectAnalyticsAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* projectAnalyticsAPISaga() {
    try {
        yield takeLatest(PROJECT_ANALYTICS.API_PROJECT_ANALYTICS_DATA, getProjectAnalyticsAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}