import React, { useEffect, useState } from "react";

export const FormContext = React.createContext({
    formView: '',
    dropdownData: {},
    fieldsData: {},
    invalidFields: [],
    databaseValues: {},
    toggleFormView: (view, data) => { },
    toggleFormValues: (_id, value) => { },
    toggleSavedValues: (field, hrid) => { },
    toggleDropdownData: (_id, list) => { },
    logInvalidFields: (_id) => { },
    markFieldValid: (_id) => { },
    reCheckFieldStatus: (_id, data) => { },
    resetForm: () => { }
});

export default props => {
    const [view, setView] = useState('add');
    const [dbData, setdbData] = useState({
        expertType: [],
        expertContact: [],
        projectType: [],
        providers: []
    });
    const [formValues, setFormValues] = useState({
        formNotTouched: true,
        id: 0,
        chargeCode: '',
        requestTitle: '',
        projectType: '',
        caseTeam: '',
        requestDescription: '',
        clientIndustry: '',
        offLimitCompanies: '',
        expertType: [],
        expertContact: [],
        interviewAnticipatedCount: '',
        providers: '',
        resourceManager: '',
        interviewStartDate: '',
        interviewEndDate: '',
        requestor: '',
        phoneNo: '',
        office: '',
        timezone: '',
        incQuery: false,
        totalSurvey: '',
        surveyStartDate: '',
        surveyEndDate: '',
        attachmentsFolderName: ''
    });
    const [dropdownOptions, setDropdownOptions] = useState({
        'projectType': [],
        'expertType': [],
        'providers': []
    });
    const [invalidFormFields, setInvalidFormFields] = useState([]);

    const addFormValues = (fieldId, value, isMandatory) => {
        if (isMandatory) {
            if (fieldId.includes('EndDate')) {
                if (value < new Date() && value.toDateString() != new Date().toDateString()) {
                    markFieldAsInvalid(fieldId, true);
                } else {
                    removeFieldFromInvalidList(fieldId);
                }
            }
            else if ((fieldId == 'interviewAnticipatedCount' || fieldId == 'totalSurvey') && (value < 1 || value.includes('+') || value.includes('-'))) {
                markFieldAsInvalid(fieldId);
            }
            else if (!value || value?.length == 0) {
                markFieldAsInvalid(fieldId);
            } else {
                removeFieldFromInvalidList(fieldId);
            }
        }

        setFormValues((values) => {
            const updatedItems = { ...values };
            updatedItems[fieldId] = value;
            return updatedItems;
        });
    }

    const updateDropdownOptions = (field, list) => {
        setDropdownOptions((options) => {
            const updatedItems = { ...options };
            updatedItems[field] = list;
            return updatedItems;
        });
    }

    const markFieldAsInvalid = (fieldId, markForce) => {
        let reCheckValue = formValues[fieldId];
        let invalidNumberField = !reCheckValue || (reCheckValue?.length == 0) || markForce;
        if (typeof (reCheckValue) != 'number' && (fieldId == 'interviewAnticipatedCount' || fieldId == 'totalSurvey')) {
            invalidNumberField = ((reCheckValue < 1 || reCheckValue?.includes('+') || reCheckValue?.includes('-')));
        }
        let nonEditableValues = false;
        if (view == 'edit' && (dbData[fieldId]?.length > 0)) {
            nonEditableValues = true;
        }
        if (!nonEditableValues && !invalidFormFields.includes(fieldId) && invalidNumberField && fieldId != "formNotTouched") {
            setInvalidFormFields((id) => {
                const updatedItems = [...id];
                updatedItems.push(fieldId);
                return updatedItems;
            });
        }
    }
    const removeFieldFromInvalidList = (fieldId) => {
        if (invalidFormFields.includes(fieldId)) {
            setInvalidFormFields((id) => {
                let updatedItems = [...id];
                updatedItems = updatedItems.filter(item => item !== fieldId);
                return updatedItems;
            });
        }
    }

    const checkValidFieldList = (fieldId, isMandatory) => {
        let fieldValue = formValues[fieldId];
        let isEndDateExpired = false;
        if (fieldId == "interviewEndDate") {
            isEndDateExpired = (new Date(fieldValue) < new Date() && new Date(fieldValue).toDateString() != new Date().toDateString());
        }
        if (!fieldValue || fieldValue?.length == 0 || isEndDateExpired) {
            setInvalidFormFields((id) => {
                let updatedItems = [...id];
                if (isMandatory) {
                    if (!updatedItems.includes(fieldId)) {
                        updatedItems.push(fieldId);
                    }
                } else {
                    if (updatedItems.includes(fieldId)) {
                        updatedItems = updatedItems.filter(item => item !== fieldId);
                    }
                }
                return updatedItems;
            });
        }
    }
    const resetFormData = () => {
        setInvalidFormFields([]);
        setFormValues({
            formNotTouched: true,
            id: 0,
            chargeCode: '',
            requestTitle: '',
            projectType: '',
            caseTeam: '',
            requestDescription: '',
            clientIndustry: '',
            offLimitCompanies: '',
            expertType: [],
            expertContact: [],
            interviewAnticipatedCount: '',
            providers: '',
            resourceManager: '',
            interviewStartDate: '',
            interviewEndDate: '',
            requestor: '',
            originalRequestorHrid: '',
            hrId: '',
            phoneNo: '',
            office: '',
            timezone: '',
            incQuery: false,
            totalSurvey: '',
            surveyStartDate: '',
            surveyEndDate: '',
            attachmentsFolderName: ''
        });
    }

    const updateFormData = (userView, data) => {
        setView(userView);
        if (userView == 'edit') {
            setFormValues((values) => {
                const updatedItems = { ...values, ...data };
                updatedItems['projectType'] = updatedItems['projectType']?.name ? updatedItems['projectType'].name : '';
                updatedItems['clientIndustry'] = updatedItems['clientIndustries'] ? updatedItems['clientIndustries'] : '';
                updatedItems['interviewStartDate'] = updatedItems['interviewAnticipatedStartDate'];
                updatedItems['interviewEndDate'] = updatedItems['interviewAnticipatedEndDate'];

                if (data.externalNetworks.length > 0) {
                    updatedItems['externalNetworks'] = data.externalNetworks;
                }

                const requestorRec = data.caseTeamMembers.findIndex((c) => c.memberType == "requestor");
                if (requestorRec > -1) {
                    updatedItems['hrId'] = data.caseTeamMembers[requestorRec].hrId;
                    updatedItems['requestorName'] = data.caseTeamMembers[requestorRec].memberName;
                    updatedItems['requestor'] = data.caseTeamMembers[requestorRec].memberEmail;
                    updatedItems['office'] = data.caseTeamMembers[requestorRec].memberHomeOffice;
                    updatedItems['timezone'] = data.caseTeamMembers[requestorRec].memberTimezone;
                    updatedItems['phoneNo'] = [data.caseTeamMembers[requestorRec].memberIsdCode, data.caseTeamMembers[requestorRec].memberPhoneNumber];
                }
                if (data.expertTypes?.length > 0) {
                    let savedList = [];
                    data.expertTypes.forEach((ele) => {
                        if (!savedList.includes(ele.expertTypeName)) {
                            savedList.push(ele.expertTypeName);
                        }
                    });
                    updatedItems['expertType'] = savedList;
                }
                return updatedItems;
            });
            setdbData((db) => {
                const savedItems = { ...db };
                if (data.expertTypes.length > 0) {
                    let expertList = [];
                    data.expertTypes.forEach((ele) => {
                        expertList.push({ name: ele.expertTypeName, member: [] });
                        data.caseTeamMembers.forEach((mem) => {
                            if (mem.expertTypes?.length > 0) {
                                mem.expertTypes.forEach((caseEle) => {
                                    if (caseEle.expertTypeName == ele.expertTypeName) {
                                        const recordIndex = expertList.length - 1;
                                        expertList[recordIndex].member.push(mem.hrId);
                                    }
                                });
                            }
                        });

                    });

                    savedItems['expertType'] = expertList;
                }
                if (data.externalNetworks.length > 0) {
                    savedItems['providers'] = data.externalNetworks;
                }
                return savedItems;
            });
        } else {
            resetFormData();
        }
    }

    const removedFromSavedValues = (field, hrid) => {
        setFormValues((values) => {
            const updatedItems = { ...values };
            updatedItems[field] = updatedItems[field].filter((i) => i.hrId != hrid);
            return updatedItems;
        });
    }

    return <FormContext.Provider value={{
        formView: view, dropdownData: dropdownOptions, invalidFields: invalidFormFields, fieldsData: formValues, databaseValues: dbData,
        toggleFormView: updateFormData,
        toggleFormValues: addFormValues,
        toggleSavedValues: removedFromSavedValues,
        toggleDropdownData: updateDropdownOptions,
        logInvalidFields: markFieldAsInvalid,
        markFieldValid: removeFieldFromInvalidList,
        reCheckFieldStatus: checkValidFieldList,
        resetForm: resetFormData
    }}>{props.children}</FormContext.Provider>
};
