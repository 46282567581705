import { put, call, takeLatest } from 'redux-saga/effects';
import CONFIG from "config";
import { NO_ACCESS } from "redux/constants";
import { doAxiosRequest } from "config-axios";
import {
    getNoAccessAPIDataPending,
    getNoAccessAPIDataSuccess,
    getNoAccessAPIDataFailure
} from "./NoAccess.actions";

import { setError } from '../error/error.actions';


export function* noAccessAPIData(action) {
    try {
        const { API_URL: { NO_ACCESS } } = CONFIG;
        console.log('NO_ACCESS:', NO_ACCESS);
        const paramURL = NO_ACCESS;
        
        const axiosConfig = {
            method: 'POST',
            endpoint: paramURL,
            headers: {
                'x-api-key': CONFIG.BE_X_API_KEY,
                'content-type': CONFIG.HTTP_CONTENT_TYPES.json,
            },
            params: action?.payload
        };
        yield put(getNoAccessAPIDataPending(action?.payload));
        const response = yield call(doAxiosRequest, axiosConfig);
        yield put(getNoAccessAPIDataSuccess(response));
    }
    catch (err) {
        yield (put(setError(err)));
        yield put(getNoAccessAPIDataFailure(CONFIG.DISPLAY_TEXT.ERROR_MESSAGES.NOT_FOUND));
    }
}

export default function* noAccessAPISaga() {
    try {
        yield takeLatest(NO_ACCESS.API_NO_ACCESS, noAccessAPIData);

    } catch (error) {
        yield (put(setError(error)));
    }
}