import { NOTIFICATIONS } from "redux/constants";
export const name = "notifications";

// initial state
export const initialState = {
    loading: true,
    error: false,
    errorMessage: "",
    notifcationsDetails: [],
    noRecordFound: false,
};

// reducer selectors
export const selectors = {
    getLoading: state => state[name].loading,
    getError: state => state[name].error,
    getErrorMessage: state => state[name].errorMessage,
    getNotifcationsDetails: (state) => state[name]?.notifcationsDetails ? state[name]?.notifcationsDetails : [],
    getNoRecordFound: state => state[name].noRecordFound,
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS.API_NOTIFICATIONS_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action?.payload?.data,
            };
        case NOTIFICATIONS.API_NOTIFICATIONS_RESULTS_PENDING:
            return {
                ...state,
                loading: true,
                noRecordFound: false,
                error: false,
                errorMessage: '',
            };
        case NOTIFICATIONS.API_NOTIFICATIONS_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                noRecordFound: action.payload?.data?.length <= 0,
                notifcationsDetails: action?.payload,
            };
        default:
            return state;
    }
}