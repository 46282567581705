import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from "components/shared/Modal";
import { ReactComponent as GreenIcon } from 'assets/images/down_arrow.svg'
import { Dropdown } from 'semantic-ui-react';
import { disputeCallAPIData, clearDisputeCallAPIData } from 'redux/actions/disputeCall/disputeCall.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as disputeCallReducer } from 'redux/reducers/disputeCall/disputeCall_reducer';
import { getCallsAPIData } from 'redux/actions/calls/calls.actions';
import queryString from 'query-string';
import './DisputeCall.scss';

export const DisputeCall = ({ disputePayload, handleClosePopup }) => {
    const disputeData = useSelector(disputeCallReducer?.getDisputeCallsData);
    const queryParam = queryString.parse(location.search)?.interviewId;
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState('');
    const [validationError, setValidationError] = useState(false);
    const [getDescription, setDescription] = useState('');
    const disputeCallValues = [
        { key: '1', text: 'Wrong call duration logged', value: 'Wrong call duration logged' },
        { key: '2', text: 'Call ended within the test window', value: 'Call ended within the test window' },
        { key: '3', text: 'Expert Quality Issue', value: 'Expert Quality Issue' },
        { key: '4', text: 'Connectivity Issue', value: 'Connectivity Issue' },
        { key: '5', text: 'Agreed proposal call – should not be charged', value: 'Agreed proposal call – should not be charged' },
        { key: '6', text: 'Other', value: 'Other' },
    ];

    useEffect(() => {
        if (disputeData?.length > 0) {
            let callsQueryData = {
                requestId: queryParam,
                expertName: null,
                expertTypes: null,
                relevantCompanies: null,
                status: null,
                networkProviders: null,
            }
            handleClosePopup();
            dispatch(getCallsAPIData(callsQueryData, 0));
        }
        dispatch(clearDisputeCallAPIData());
    }, [disputeData]);

    const handleDescription = (event) => {
        setDescription(event?.target?.value);
    }

    const handleDisputeCallChange = (event, sortValue) => {
        if (sortValue?.value !== 'Other') {
            setValidationError(false);
        }
        setSelectedValue(sortValue?.value);
    }

    const handleBtnDisable = () => {
        if (selectedValue) {
            if (selectedValue !== 'Other') {
                return false
            }
            else if (selectedValue === 'Other') {
                if (!getDescription) {
                    return true
                }
                else {
                    return false
                }
            }
        }
        else {
            return true
        }
    }

    const handleBtnClassDisable = () => {
        if (selectedValue) {
            if (selectedValue !== 'Other') {
                return 'approve--btn'
            }
            else if (selectedValue === 'Other') {
                if (!getDescription) {
                    return 'approve--btn approve--btn-disabled '
                }
                else {
                    return 'approve--btn'
                }
            }
        }
        else {
            return 'approve--btn-disabled approve--btn'
        }
    }

    const handleSubmit = () => {
        if (selectedValue === 'Other' && getDescription === '') {
            setValidationError(true);
        }
        else {
            setValidationError(false);
        }
        const queryData = {
            "requestId": disputePayload?.requestId,
            "projectId": disputePayload?.projectId,
            "id": disputePayload?.id,
            "expertProfile": {
                "id": disputePayload?.expertProfile?.id,
                "profileId": disputePayload?.expertProfile?.profileId
            },
            "reason": selectedValue,
            "description": getDescription
        }
        dispatch((disputeCallAPIData(queryData)));
    }

    return (
        <div className='disputcall__wrapper'>
            <Modal
                isOpen={true}
                className="disputcall--modal">

                <div className="disputcall__header">
                    Dispute Call
                </div>

                <div className="disputcall__body">
                    <div className='body__subText'>
                        Select a Reason for disputing the call
                    </div>
                    <Dropdown
                        className="disputcall--drop"
                        placeholder='Select'
                        fluid
                        selection
                        value={selectedValue}
                        options={disputeCallValues}
                        onChange={handleDisputeCallChange}
                        icon={<GreenIcon />} />

                    <div className='body__inputText'>
                        Description of Dispute
                    </div>
                    <input type='text'
                        className={validationError ? "input__validation disputecall__input" : "disputecall__input"} placeholder={'Please describe the dispute cost in detail.'}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        value={getDescription}
                        onChange={(e) => handleDescription(e)}
                    ></input>
                    <div className={validationError ? 'body__afterText redText' : 'body__afterText'}>
                        Please select a reason for the dispute before submitting.
                    </div>
                </div>

                <div className="disputcall__footer">
                    <button className="cancel--btn" onClick={() => handleClosePopup()}>Cancel</button>
                    <button className={handleBtnClassDisable()} disabled={handleBtnDisable()} onClick={() => handleSubmit()}>Submit Issue</button>
                </div>
            </Modal>
        </div>
    )
}

DisputeCall.propTypes={
    disputePayload: PropTypes.shape({
        requestId: PropTypes.string,
        projectId: PropTypes.string,
        id: PropTypes.string,
        expertProfile: PropTypes.shape({
            id: PropTypes.string.isRequired,
            profileId: PropTypes.string.isRequired
        })
    }).isRequired,
    handleClosePopup: PropTypes.func.isRequired
}