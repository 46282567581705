import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as peopleSelector } from '../../redux/reducers/peopleresults/peopleresults_reducer';
import { Image } from 'semantic-ui-react';
import logoIcon from "assets/images/logo.svg";
import notificationIcon from "assets/images/header_notification_icon.svg";
import { sendToLocalStorage } from 'utils/localStorage/localStorage';
import { getRandomColor } from "utils/helpers/helpers";
import { getEmployeeDesignation } from 'redux/actions/employeeDesignation/employeeDesignation.actions';
import { getStaffId, initiateRequestFormClickAnalytics, globalAdobeAnalytics } from '../../analytics.js';
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { Notifications } from 'components/Notifications';
import { getUserId } from "utils/auth/auth";
import "./Header.scss";

const Header = ({ history, isError }) => {
  const getPeopleDataReducer = useSelector(peopleSelector.getPeopleResults);
  const [userProfile, setUserProfile] = useState(getPeopleDataReducer[0]?.picture || '');
  const [userDetails, setUserDetails] = useState({ nameInitials: '', bgColor: '' });
  const [getNotiCount, setNotiCount] = useState(null);
  const [hrid, setHrid] = useState();
  const [showNotificationsPopup, setNotificationsPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Disable body scroll when notification popup is open
    if (showNotificationsPopup) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showNotificationsPopup]);

  useEffect(() => {
    fetchHrId();
    fetchLoggedInUserEmail();
  }, []);

  const fetchLoggedInUserEmail = async () => {
    const hrid = await getUserId();
    const resp = await getUserProfilePictures([hrid]);
    if (resp?.length > 0) {
      getStaffId(resp[0]?.email || '');
    }
  }

  useEffect(() => {
    if (getPeopleDataReducer[0] && getPeopleDataReducer[0].id == hrid) {
      const profilePic = getPeopleDataReducer[0]?.picture?.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : getPeopleDataReducer[0]?.picture;
      setUserProfile(profilePic);
      const nameArray = getPeopleDataReducer[0]?.preferredName ? getPeopleDataReducer[0]?.preferredName.split(', ') : [];
      let userInitials = '';
      if (nameArray.length > 0) {
        userInitials = nameArray.length > 1 ? nameArray[1].charAt(0) + nameArray[0].charAt(0) : nameArray[0].charAt(0);
      }
      setUserDetails(
        {
          nameInitials: userInitials,
          bgColor: getRandomColor()
        });
      sendToLocalStorage('phoneNumber', getPeopleDataReducer[0]?.phones || []);
      dispatch(getEmployeeDesignation(getPeopleDataReducer[0]?.id));
    }
  }, [getPeopleDataReducer, hrid]);

  const fetchHrId = async () => {
    const hrid = await getUserId();
    setHrid(hrid);
  }

  const setNotiTotal = (noti) => {
    setNotiCount(noti);
  }

  const handleButtonClick = () => {
    sessionStorage.clear('hasFormLoaded')
    if (window?.location?.pathname === '/') {
      globalAdobeAnalytics('EEH Request Form', 'EEH Home Page');
      initiateRequestFormClickAnalytics('EEH Request Form', 'Initiate Expert Request', 'EEH Home Page');
    }
    else {
      globalAdobeAnalytics('EEH Request Form', 'EEH Request Details Page');
      initiateRequestFormClickAnalytics('EEH Request Form', 'Initiate Expert Request', 'EEH Request Details Page');
    }
    history.push("/ExpertRequest/initiate");
  };

  let activepath = window?.location?.pathname;

  const handleLogoClick = () => {
    sendToLocalStorage('notLoadHome', true);
    removeFromLocalStorage('hasDetailsLoaded');
    if (window.location?.pathname === '/') {
      globalAdobeAnalytics('EEH Home Page', 'EEH Home Page');
    }
    else if (window.location?.pathname === '/requestDetails') {
      globalAdobeAnalytics('EEH Home Page', 'EEH Request Details Page');
    }
    else if (window?.location?.pathname === '/ExpertRequest/initiate') {
      globalAdobeAnalytics('EEH Home Page', 'EEH Request Form');
    }
    history.push("/")
  }

  const toggleNotificationsPopup = () => {
    setNotificationsPopup(!showNotificationsPopup); // Toggle the popup visibility
  };

  return (
    <div className="mainheader__wrap">
      <div className="mainheader__logo">
        <Image src={logoIcon} alt="logo"
          onClick={() => {
            handleLogoClick();
          }} />
      </div>

      <div className='mainheader__midSection'>
        <div className={window.location.href.indexOf('projectAnalytics') > -1 ? "midSection__head active--page" : "midSection__head"} onClick={() => history?.push('/projectAnalytics')}>
          Consolidated Project Analytics
        </div>
      </div>

      <div className="mainheader__rightSection">
        {!activepath.includes('/ExpertRequest') && activepath !== '/error' && isError !== true &&
          <button className="initiate--expbtn" onClick={handleButtonClick}>
            Initiate Expert Request
          </button>
        }

        {
          isError !== true &&
          <div className="notiUser__wrap">
            <div className="noti__imgWrap">
              <Image id="notification_icon" className={showNotificationsPopup ? "noti-open bell-img" : "bell-img"} src={notificationIcon} alt="notificationIcon"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleNotificationsPopup();
                }} />
              {showNotificationsPopup && <div className="noti-count">{getNotiCount}</div>}
            </div>
            {
              showNotificationsPopup &&
              <div className="noti-popup">
                <Notifications closePopup={() => setNotificationsPopup(false)} noticount={setNotiTotal} />
              </div>
            }
            {userProfile ?
              <Image className="header--img" src={userProfile} alt="defaultUserIcon" /> :
              <span style={{ background: userDetails.bgColor }} className={`initial-icon user--img custom--profile--pic`}>
                {userDetails.nameInitials?.length > 0 ? userDetails.nameInitials : 'N/A'}
              </span>
            }
          </div>
        }
      </div>
      {showNotificationsPopup && <div className="overlay" onClick={() => setNotificationsPopup(false)}></div>}
    </div>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired
}

export default withRouter(Header);
