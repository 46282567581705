import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./CommentsPane.scss";
import ArrowUp from "assets/images/arrow-right-up.svg";
import DefaultImg from "assets/images/default-image.svg";
import { Image } from "semantic-ui-react";
import { getRandomColor } from "utils/helpers/helpers";
import moment from 'moment';

export const CommentsPane = ({ config, sliderCloseHandler, saveCommentHandler, selectedExpert }) => {

    const [newComment, setNewComment] = useState();
    const [prevComments, setPrevComments] = useState([]);
    const [peopleAPIError, setPeopleAPIError] = useState(false);

    useEffect(() => {
        if (config.savedComments?.length > 0) {
            setPrevComments(config.savedComments);
            config.savedComments.forEach((comment) => {
                getCaseteamMemberDetails(comment.user);
            });
        } else {
            setPrevComments([]);
        }
    }, [config.savedComments]);

    const getCaseteamMemberDetails = async (hrId) => {
        const resp = await getUserProfilePictures([hrId]);
        let requiredDetails = {};
        if (resp && resp[0]) {
            setPeopleAPIError(false);
            requiredDetails = {
                'hrId': resp[0].id,
                'name': resp[0].firstName + ' ' + resp[0].lastName,
                'title': resp[0].jobTitle,
                'picture': resp[0].picture.includes('2b57bfed-513c-4f78-beca-f6a3dd6a75be.jpg') ? null : resp[0].picture,
                'nameInitials': resp[0].firstName.charAt(0) + resp[0].lastName.charAt(0),
                'bgColor': getRandomColor()
            }
            const formatComments = [...config.savedComments];
            const commentIndex = formatComments.findIndex((c) => c.user == resp[0].id && !c.userDetails);
            if (commentIndex > -1) {
                formatComments[commentIndex].userDetails = requiredDetails;
                formatComments[commentIndex].commentDate = moment(formatComments[commentIndex].createdTimestamp, "YYYY-MM-DD").format('DD MMM YYYY');
            }
            setPrevComments(formatComments);
            return true;
        }
        else {
            setPeopleAPIError(true);
        }
    };


    return (
        <SlidingPane
            className="comments-pane"
            overlayClassName="right-sliding-pane"
            isOpen={config.open}
            title=""
            subtitle=""
            width="520px"
            hideHeader={true}
            onRequestClose={() => sliderCloseHandler()}
        >
            <div className="pane--head">Comments for {selectedExpert}</div>
            <div className="top-block">
                <textarea className="form-textarea" placeholder="Add comment" value={newComment} onChange={(e) => setNewComment(e.target.value)} />
                <button
                    style={{
                        position: 'absolute',
                        top: '165px',
                        right: '32px',
                        zIndex: 1,
                        background: '#197A56',
                        border: '0px',
                        width: '35px',
                        height: '35px'
                    }}
                    onClick={() => { saveCommentHandler(config.profileId, newComment); setNewComment(''); }}
                >
                    <Image src={ArrowUp} />
                </button>
            </div>
            <hr />
            <div>
                <div className="grid-header-count">
                    Comments
                    <div className='count--badge'>{prevComments?.length || '0'}</div>
                </div>
                {prevComments?.length > 0 && prevComments.map((comment, index) =>
                    <>
                        <div key={comment.user + index} className="comment-block">
                            <div className="comment-user-info">
                                <div className="comment-user-profile">
                                    {
                                        !peopleAPIError ?
                                            <>
                                                {comment.userDetails?.picture ?
                                                    <Image src={comment.userDetails?.picture} alt="profile NP" className='user--img' />
                                                    : (comment.userDetails?.bgColor && comment.userDetails.nameInitials && <span
                                                        style={{ background: comment.userDetails.bgColor }}
                                                        className={`user--img custom--profile--pic comment-pic-holder`}>
                                                        {comment.userDetails.nameInitials}
                                                    </span>)
                                                }
                                            </>
                                            :
                                            <Image src={DefaultImg} alt="DefaultImg" />
                                    }

                                </div>
                                <div className="commenter-details">
                                    {
                                        !peopleAPIError ?
                                            <>
                                                <div className="commenter-date-name">
                                                    <span className="commenter-name">{comment.userDetails?.name}</span>
                                                    <div className="comment-time">{comment.commentDate}</div>
                                                </div>
                                                <span className="commenter-title">{comment.userDetails?.title}</span>
                                            </>
                                            :
                                            <div className="comments_error">
                                                <div className="people--error left--align">
                                                    Something went wrong, please refresh.
                                                </div>
                                            </div>
                                    }

                                </div>

                            </div>
                            <div className="comment-text">{comment.comment}
                            </div>
                        </div>
                        <hr />
                    </>
                )}
            </div>

        </SlidingPane>
    );
};

CommentsPane.propTypes = {
    selectedExpert: PropTypes.string.isRequired,
    saveCommentHandler: PropTypes.func.isRequired,
    sliderCloseHandler: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired
}