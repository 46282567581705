import React, { useEffect, useState } from "react";
import Image from "components/shared/Image";
import PropTypes from "prop-types";
import CostIcon from "assets/images/dollar.svg";
import DisputeIcon from 'assets/images/dispute-call.svg';
import CrossIcon from 'assets/images/red-cross.svg';
import PhoneIcon from 'assets/images/phone-icon.svg';
import PhoneGreenIcon from 'assets/images/phone-green.svg';
import LocationPin from 'assets/images/location-blue.svg';
import PhoneBlue from 'assets/images/phone-blue.svg';
import { TeamMembers } from "components/TeamMembers";
import { formatDate } from "utils/formatDate/formatDate";
import { getUserProfilePictures } from "api/getUserProfilePictures";
import { getUserId } from "utils/auth/auth";
import { calculateTimeDifference } from "utils/helpers/helpers";
import { CancellationPopup } from 'components/CancellationPopup';
import { DisputeCall } from 'components/DisputeCall';
import './CallsTemplate.scss';
import { ScheduleCall } from "components/ScheduleCall";
import { propTypes } from "react-bootstrap/esm/Image";

export const CallsTemplate = ({ data, handleExpandedState, caseTeamMembers, refreshCallsGrid, isClosedRequest }) => {
    const [callsData, setCallsData] = useState({});
    const [attendees, setAttendees] = useState([]);
    const [userTimezone, setUserTimezone] = useState('Asia/Kolkata');
    const [getCancellation, setCancellation] = useState(false);
    const [getLateCancellation, setLateCancellation] = useState(false);
    const [showDisputeCall, setShowDisputeCall] = useState(false);
    const [openRescheduleCall, setOpenRescheduleCall] = useState({
        open: false,
        selectedIndex: -1,
        selectedRecord: null,
        isFollowup: false,
        lastRecord: false
    });

    // Fetch the user timezone
    const getUserTimeZone = async () => {
        try {
            const hrid = await getUserId();
            const resp = await getUserProfilePictures([hrid]);

            if (resp?.length > 0 && resp[0]?.hostOfficeLocation?.timezone) {
                setUserTimezone(resp[0].hostOfficeLocation.timezone);
            }
        } catch (error) {
            setUserTimezone('Error fetching timezone');
        }
    };

    const handleClosePopup = () => {
        setCancellation(false);
        setLateCancellation(false);
        setShowDisputeCall(false);
        handleExpandedState();
    }

    useEffect(() => {
        getUserTimeZone();
        setAttendees([]);
    }, []);

    useEffect(() => {
        setCallsData(data);
        let obj = [];
        if (data?.attendees?.length > 0) {
            data?.attendees?.map((item, index) => {
                obj?.push({ hrId: item?.attendeeHrId });
            })

        } else {
            caseTeamMembers?.map((item, index) => {
                obj?.push({ hrId: item?.hrId });
            });
        }
        setAttendees(obj);
    }, [data]);

    const handleCancel = async (utcDateTime) => {
        let diff = await handleTimeDiff(utcDateTime);
        if (diff <= 4) {
            setCancellation(false);
            setLateCancellation(true);
        }
        if (diff > 4) {
            setCancellation(true);
            setLateCancellation(false);
        }
        setShowDisputeCall(false);
    }

    const handleTimeDiff = (utcDate) => {
        // Create a Date object from the UTC timestamp
        let utcDateObj = new Date(utcDate);
        // Get the current date and time in the user's local time zone
        let currentDateObj = new Date();
        // Calculate the time difference in milliseconds
        let timeDiff = utcDateObj - currentDateObj;

        // Convert time difference to hours, minutes, and seconds
        let diffInHours = Math.floor(timeDiff / (1000 * 60 * 60)),
            diffInMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        // Convert the difference into hours with minutes as a decimal
        let diffInHoursWithDecimal = diffInHours + (diffInMinutes / 60);

        return Promise.resolve(diffInHoursWithDecimal);

    }

    const handleDisputeCall = () => {
        setShowDisputeCall(true);
    }

    const CompletedCallsLocation = (data) => {
        // Prepare the data outside JSX (before the return)
        const startDate = new Date(data?.startDateTime);
        const endDate = new Date(data?.endDateTime);

        const formattedStartDate = formatDate(startDate?.toLocaleDateString());
        const options = {
            timeZone: userTimezone,
            hour12: true,
            hour: '2-digit',
            minute: '2-digit',
        };

        const localStartDateTime = startDate?.toLocaleTimeString('en-US', options);
        const localEndDateTime = endDate?.toLocaleTimeString('en-US', options);
        // Calculate the time difference
        const { diffHours, remainingMinutes } = calculateTimeDifference(localStartDateTime, localEndDateTime);
        const timeDiff = `${diffHours} Hr${diffHours !== 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} Min` : ''}`;

        // Construct the final string to display
        const timeSlotText = `${formattedStartDate} | ${localStartDateTime} - ${localEndDateTime} ${userTimezone} (${timeDiff})`;
        return timeSlotText;
    }

    const rescheduleCallHandler = (callsDetails, isReschedule) => {
        const isExpertSlotSelection = callsDetails.selectedSlots.length > 0 ? callsDetails.selectedSlots[0].isExpertSlot : false;

        const rescheduleData = {
            expertName: callsDetails.expertProfile.expertName,
            projectId: callsDetails.projectId,
            duration: callsDetails.duration,
            callId: callsDetails.id,
            id: callsDetails.expertProfile.id,
            selectedSlots: { ...callsDetails.callManagementScheduledSlot, isExpertSlot: isExpertSlotSelection },
            profileId: callsDetails.expertProfile.profileId,
            networkProvider: callsDetails.expertProfile.networkProvider,
            availableSlots: callsDetails.expertProfile.availableSlots,
            status: callsDetails.callStatus
        };
        setOpenRescheduleCall({
            open: true,
            callData: rescheduleData,
            isReschedule: isReschedule,
            isFollowup: (!isReschedule || data?.followUpCall),
            requestId: callsDetails.requestId
        });
    }
    const handleReschedulePopupClose = (flag) => {
        setOpenRescheduleCall({
            open: false,
            callData: null
        });
        refreshCallsGrid(flag);
    }

    return (
        <>
            {openRescheduleCall?.open && <ScheduleCall
                open={openRescheduleCall.open}
                data={openRescheduleCall.callData}
                caseTeamMembers={attendees}
                requestId={openRescheduleCall.requestId}
                isRescheduleWindowFlag={openRescheduleCall.isReschedule}
                isFollowUpCallFlag={openRescheduleCall.isFollowup}
                closePopupHandler={(flag) => handleReschedulePopupClose(flag)}
            />}
            <div className="callsTemplate--wrapper">
                {
                    data?.callStatus?.toLowerCase() === 'completed' ?
                        <div className="completedcalls--template">
                            <div className="completedcalls--header">
                                <div className="completedcalls--leftHead">
                                    <div className="completedcalls--location">
                                        <Image src={LocationPin} alt="LocationPin" />
                                        <span className="bold--text">Expert’s Country : </span>{data?.expertProfile?.country}
                                    </div>
                                    {
                                        data?.totalCost &&
                                        <div className="completedcalls--cost">
                                            <Image src={CostIcon} alt="CostIcon" />
                                            <span className="bold--text">Total Cost : </span>{data?.totalCost}
                                        </div>
                                    }
                                </div>

                                <div className="completedcalls--rightHead">
                                    <button className="completedcalls--disputecall" disabled={isClosedRequest} onClick={() => handleDisputeCall()}>
                                        <Image src={DisputeIcon} alt="DisputeIcon" />
                                        Dispute Call
                                    </button>
                                    {data?.followUpCall &&
                                        <div className="completedcalls--call">
                                            Follow Up Call
                                        </div>}
                                </div>
                            </div>

                            <div className="completedcalls--body">
                                {
                                    attendees?.length > 0 &&
                                    <div className="attendees--head">Attendees</div>
                                }
                                <div className="attendees--data">
                                    <TeamMembers data={attendees} templatePage={true} />
                                </div>
                            </div>
                            <button className="reschedule--call--secondarybtn" disabled={isClosedRequest} onClick={() => rescheduleCallHandler(data, false)}>
                                <Image src={PhoneGreenIcon} alt="PhoneIcon" />
                                Schedule Another Call
                            </button>
                        </div>
                        :
                        <></>
                }
                {
                    data?.callStatus?.toLowerCase() === 'upcoming' &&
                    <div className="completedcalls--template">
                        <div className="completedcalls--header">
                            <div className="completedcalls--leftHead">
                                <div className="completedcalls--location">
                                    <Image src={LocationPin} alt="LocationPin" />
                                    <span className="bold--text">Expert’s Country : </span>{data?.expertProfile?.country}
                                </div>
                            </div>

                            <div className="completedcalls--rightHead">
                                {data?.followUpCall &&
                                    <div className="completedcalls--call">
                                        Follow Up Call
                                    </div>}
                            </div>
                        </div>

                        <div className="completedcalls--body">
                            {
                                attendees?.length > 0 &&
                                <div className="attendees--head">Attendees</div>
                            }
                            <div className="attendees--data">
                                <TeamMembers data={attendees} templatePage={true} />
                            </div>

                            <div className="completedcalls--bodyFooter">
                                <button className="bodyFooter--leftSection" disabled={isClosedRequest} onClick={() => handleCancel(data?.callManagementScheduledSlot?.startDateTime)}>
                                    <Image src={CrossIcon} alt="CrossIcon" />
                                    Cancel Call
                                </button>
                                <button className="bodyFooter--rightSection" disabled={isClosedRequest} onClick={() => rescheduleCallHandler(data, true)}>
                                    <Image src={PhoneIcon} alt="PhoneIcon" />
                                    Reschedule
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {
                    data?.callStatus?.toLowerCase() === 'requested' &&
                    <div className="completedcalls--template">
                        <div className="completedcalls--header completedcalls--headerWrap">
                            <div className="calls__leftWrap">
                                <div className="completedcalls--leftHead">
                                    <div className="completedcalls--location">
                                        <Image src={LocationPin} alt="LocationPin" />
                                        {data?.expertProfile?.country}
                                    </div>
                                    <div className="completedcalls--location">
                                        <Image src={PhoneBlue} alt="PhoneBlue" />
                                        TBD (Selected / Proposed Time Slots: &nbsp;
                                        {data?.selectedSlots?.map((subItem) => {
                                            return CompletedCallsLocation(subItem);
                                        }).join(', ')}
                                        )
                                    </div>
                                </div>
                            </div>
                            <div className="completedcalls--rightHead">
                                {data?.followUpCall &&
                                    <div className="completedcalls--call">
                                        Follow Up Call
                                    </div>}
                            </div>
                        </div>
                        <div className="completedcalls--body">
                            {
                                attendees?.length > 0 &&
                                <div className="attendees--head">Attendees</div>
                            }
                            <div className="attendees--data">
                                <TeamMembers data={attendees} templatePage={true} />
                            </div>
                        </div>
                    </div>
                }
                {
                    data?.callStatus?.toLowerCase() === 'cancelled' &&
                    <div className="completedcalls--template">
                        <div className="completedcalls--header">
                            <div className="completedcalls--leftHead">
                                <div className="completedcalls--location">
                                    <Image src={LocationPin} alt="LocationPin" />
                                    <span className="bold--text">Expert’s Country : </span>{data?.expertProfile?.country}
                                </div>
                            </div>
                        </div>
                        <div className="completedcalls--body">
                            <div className="attendees--head">Reason for Cancellation</div>
                            <div className="attendees--data">
                                {data?.reason || 'NA'}
                                {/* Due to technical issues, we are unable to proceed with the call. */}
                            </div>

                            <button className="reschedule--call" disabled={isClosedRequest} onClick={() => rescheduleCallHandler(data, true)}>
                                <Image src={PhoneIcon} alt="PhoneIcon" />
                                Reschedule
                            </button>
                        </div>
                    </div>
                }
            </div >
            {
                getCancellation === true &&
                <CancellationPopup lateCancellation={false} handleClosePopup={handleClosePopup} cancelData={callsData} />
            }
            {
                getLateCancellation === true &&
                <CancellationPopup lateCancellation={true} handleClosePopup={handleClosePopup} cancelData={callsData} />
            }
            {
                showDisputeCall === true &&
                <DisputeCall showDisputeCall={true} handleClosePopup={handleClosePopup} disputePayload={callsData} />
            }
        </>
    )
}
CallsTemplate.propTypes = {
    data: PropTypes.shape({
        attendees: PropTypes.array,  // Validate attendees as an array
        followUpCall: PropTypes.bool,
        callStatus: PropTypes.string,
        totalCost: PropTypes.string,
        expertProfile: PropTypes.shape({
            country: PropTypes.string
        }),
        selectedSlots: PropTypes.array,
        startDateTime: PropTypes.string,
        endDateTime: PropTypes.string,
        callManagementScheduledSlot: PropTypes.shape({
            startDateTime: propTypes.string,
            endDateTime: propTypes.string
        }),
        reason: PropTypes.string
    }).isRequired,
    handleExpandedState: PropTypes.func.isRequired,
    caseTeamMembers: PropTypes.array.isRequired,
    refreshCallsGrid: PropTypes.func.isRequired
};