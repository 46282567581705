// React
import React from 'react';
import { Layout } from "containers/Layout";
// React Routing
import { withRouter } from 'react-router-dom';
import CONFIG from 'config';
import LABELS from 'labels';
import ErrorComp from './Error';

// import styles
import './ErrorPage.scss';

/**
* @desc create error message 
* @returns
*/
export const ErrorPage = ({
  btnClick,
  btn2Click,
  errorCodeProp,
  match,
  error: oktaError
}) => {
  const { ERROR_CONFIG = {} } = CONFIG;
  const { INSUFFICIENT_PERMISSIONS } = LABELS;
  const code = !!errorCodeProp ? errorCodeProp?.toLowerCase() : match?.params?.errorCode?.toLowerCase();
  const { WITH_LAYOUT } = ERROR_CONFIG[code] || {};
  if (oktaError) {
    console.error('OKTA login error', JSON.stringify(oktaError));
  }
  return (
    WITH_LAYOUT ? <Layout
      id="layout__error"
      layoutClass="layout__error"
      pageTitle={INSUFFICIENT_PERMISSIONS}
    >
      <ErrorComp
        code={code}
        btnClick={btnClick}
        btn2Click={btn2Click}
      />
    </Layout>
      :
      <div className="errorContainer">
        <ErrorComp
          code={code}
          btnClick={btnClick}
          btn2Click={btn2Click}
        />
      </div>
  )
}

export default withRouter(ErrorPage);