import React from "react";
import { Table } from 'semantic-ui-react';

export const PerformanceTable = () => {

    const performanceData = [
        {
            network: 'AlphaSights (15%)',
            expertsNo: '30',
            expertsInterviewed: '56',
            conversionRate: '12%',
            standardIntSpend: ['34', '48'],
            premiumIntSpend: ['3', '600']
        },
        {
            network: 'AlphaSights (15%)',
            expertsNo: '30',
            expertsInterviewed: '56',
            conversionRate: '12%',
            standardIntSpend: ['34', '48'],
            premiumIntSpend: ['3', '600']
        },
        {
            network: 'AlphaSights (15%)',
            expertsNo: '30',
            expertsInterviewed: '56',
            conversionRate: '12%',
            standardIntSpend: ['34', '48'],
            premiumIntSpend: ['3', '600']
        },
        {
            network: 'AlphaSights (15%)',
            expertsNo: '30',
            expertsInterviewed: '56',
            conversionRate: '12%',
            standardIntSpend: ['34', '48'],
            premiumIntSpend: ['3', '600']
        },
        {
            network: 'AlphaSights (15%)',
            expertsNo: '30',
            expertsInterviewed: '56',
            conversionRate: '12%',
            standardIntSpend: ['34', '48'],
            premiumIntSpend: ['3', '600']
        }
    ]
    return (
        <div className="PerformanceTable__wrap">
            <Table sortable celled className='performance--table'>
                <Table.Header className='perTable__header'>
                    <Table.Row>
                        <Table.HeaderCell className='performance-header--title'>Network & Participation Rate</Table.HeaderCell>
                        <Table.HeaderCell className='performance-header--title'>Experts Submitted</Table.HeaderCell>
                        <Table.HeaderCell className='performance-header--title'>Experts Interviewed</Table.HeaderCell>
                        <Table.HeaderCell className='performance-header--title'>Conversion Rate</Table.HeaderCell>
                        <Table.HeaderCell className='performance-header--title'>Standard Interviews & Spend</Table.HeaderCell>
                        <Table.HeaderCell className='performance-header--title'>Premium Interviews & Spend</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body className="performance__body">
                    {
                        performanceData?.map((item, index) => (
                            <Table.Row>
                                <Table.Cell className='table--data'>
                                    {item?.network}
                                </Table.Cell>
                                <Table.Cell className='table--data'>
                                    {item?.expertsNo}
                                </Table.Cell>
                                <Table.Cell className='table--data'>
                                    {item?.expertsInterviewed}
                                </Table.Cell>
                                <Table.Cell className='table--data'>
                                    {item?.conversionRate}
                                </Table.Cell>
                                <Table.Cell className='table--data'>                                    
                                    <div className="intSpend__wrap">
                                        <div className="spend--left">{item?.standardIntSpend[0]}</div>
                                        <div className="spend--right">${item?.standardIntSpend[1]}</div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell className='table--data'>
                                    <div className="intSpend__wrap">
                                        <div className="spend--left">{item?.premiumIntSpend[0]}</div>
                                        <div className="spend--right">${item?.premiumIntSpend[1]}</div>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
        </div >
    )
}