import React, { use, useEffect, useState } from "react";
import "./ActionAlertWindow.scss";
import Modal from "components/shared/Modal";

import CrossIcon from "assets/images/cross-large.svg";

export const ActionAlertWindow = ({ props, closeAlertWindowHandler, children }) => {

    const [openModel, setOpenModel] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [isDisable, setDisableFlag] = useState(false);

    useEffect(() => {
        setOpenModel(props.open);
    }, [props.open]);

    useEffect(() => {
        if(!props.noInputFields){
        setDisableFlag(props.chargeCode ? false : true);
        }
    }, [props.chargeCode]);

    useEffect(() => {
        if (loading) {
            setDisableFlag(loading);
        }
    }, [loading]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);


    const buttonClickHandler = (isCancel) => {
        closeAlertWindowHandler(isCancel, props.type);
    }

    return (
        <Modal isOpen={openModel} className="action-alert__modal">
            <div className="action-alert__wrapper" style={{ width: props.popupWidth, height: props.popupHeight }}>
                {props.showCloseIcon && <img className="close-icon" src={CrossIcon} alt="close" onClick={() => buttonClickHandler(true)} />}
                <div className="action-alert__heading">
                    <span>{props.heading}</span>
                </div>
                {props.details &&
                    <div className="action-alert__details">
                        <p>{props.details}</p>
                    </div>
                }
                {children}
                <div className="action-alert__action-container">
                    {props.cancelActionTxt && <button className="outline" onClick={() => buttonClickHandler(true)}>{props.cancelActionTxt}</button>}
                    {props.proceedActionTxt && <>
                        <button className="primary" onClick={() => buttonClickHandler(false)} disabled={isDisable || props.isDisable}>{props.proceedActionTxt}</button>
                        {
                            loading === true &&
                            <div class="ui active inline medium loader custom-loader"></div>
                        }
                    </>}
                </div>
            </div>
        </Modal>
    );
};
