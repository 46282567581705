import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Layout } from "containers/Layout";
import { PrivacyConsentModal } from "components/PrivacyConsentModal";
import { getPeopleAPIData } from "redux/actions/peopleresults/peopleresults_actions";
import { selectors as peopleSelector } from '../../redux/reducers/peopleresults/peopleresults_reducer';
import { getOpenRequestAPIData } from "redux/actions/openRequests/openRequests.actions";
import { selectors as openRequestsSelector } from "redux/reducers/openRequests/openRequests_reducer";
import { OpenRequests } from "components/OpenRequests";
import { getUserId } from "utils/auth/auth";
import DownArrow from "assets/images/down_arrow.svg";
import './HomeScreen.scss';
import { getConsentAPIData } from "redux/actions/privacyConsent/privacyConsent.actions";
import { selectors as consentSelector } from "redux/reducers/privacyConsent/privacyConsent_reducer";
import { sendToLocalStorage, getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';
import { globalAdobeAnalytics, getStaffId, initiateTabClickAnalytics } from '../../analytics.js';
import { SearchableField } from "components/shared/SearchableField";
import { GuidedTour } from "components/shared/GuidedTour";

const HomeContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const popupRef = useRef(null);
    const sortData = [
        { name: 'Newest First', index: 'newest' },
        { name: 'Oldest First', index: 'oldest' }
    ];
    const [activeRequest, setActiveRequest] = useState({
        openreq: true,
        closedreq: false
    });

    const getPeopleDataReducer = useSelector(peopleSelector.getPeopleResults);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState('Newest First');
    const openRequestsData = useSelector(openRequestsSelector.getOpenRequestsResults);
    const exisitngUserFlag = useSelector(openRequestsSelector?.getExistingUser);
    const loadingData = useSelector(openRequestsSelector.getLoading);
    const noRecordFoundData = useSelector(openRequestsSelector.getNoRecordFound);
    const [requestData, setRequestsData] = useState([]);
    const [existingUser, setExistingUser] = useState(false);
    const [tourGuideId, setTourGuideId] = useState("");
    const [loading, setLoading] = useState(true);
    const [noRecordFound, setnoRecordFound] = useState(noRecordFoundData || false);
    const [hrid, setHrid] = useState('');
    const [closedRequests, setClosedRequests] = useState([]);
    const [closedRequestTotalCount, setClosedRequestTotalCount] = useState(0);
    const [openRequestsTotalCount, setOpenRequestsTotalCount] = useState(0);
    const [hideConsentPopup, setHideConsentPopup] = useState(true);
    const [showGuidedTour, setShowGuidedTourFlag] = useState(false);
    const [page, setPage] = useState(0);
    const [openPage, setOpenPage] = useState(0);
    const [isLastPage, setLastPageFlag] = useState(false);
    const [isOpenLastPage, setOpenLastPageFlag] = useState(false);
    const [searchedString, setSearchedString] = useState('');
    const consentData = useSelector(consentSelector.consentData);

    useEffect(() => {
        getStaffId(getPeopleDataReducer[0]?.email);
    }, [getPeopleDataReducer]);

    useEffect(() => {
        if (exisitngUserFlag) {
            setExistingUser(exisitngUserFlag);
            setTourGuideId("67a5abb34fd934595ad26ecf");
            // setTourGuideId("67c04c3e03fc1a4a082a84a7");
        } else {
            setExistingUser(false);
            setTourGuideId("67b2d012e818bc036ccd644d");
            // setTourGuideId("67c1a560e43c2bc83148ab0d");
        }
    }, [exisitngUserFlag]);

    useEffect(() => {
        if (consentData?.hrId) {
            setHideConsentPopup(consentData.privacyConsent);
        }
    }, [consentData]);

    useEffect(() => {
        fetchHrId();
    }, [history.location]);

    useEffect(() => {
        if (openRequestsData?.data?.length > 0) {
            if (openRequestsData.data[0].requestStatus == 'Open') {
                if (openRequestsData.currentPage == 0) {
                    setRequestsData(() => {
                        const updatedData = [...openRequestsData.data];
                        updatedData.forEach((ele) => {
                            const analyticsData = [];
                            const profileCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertProfileReceived');
                            const scheduledCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsScheduled');
                            const interviewedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertsInterviewed');
                            const completedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsCompleted');
                            analyticsData.push(ele?.requestAnalytics[profileCount]);
                            analyticsData.push(ele?.requestAnalytics[scheduledCount]);
                            analyticsData.push(ele?.requestAnalytics[interviewedCount]);
                            analyticsData.push(ele?.requestAnalytics[completedCount]);

                            ele.requestAnalytics = analyticsData;
                        });

                        return updatedData;
                    });
                    setOpenRequestsTotalCount(openRequestsData?.totalElements)
                }
                else {
                    const updatedData = requestData?.concat(openRequestsData?.data);
                    setRequestsData(() => {
                        // const updatedData = [...openRequestsData.data];
                        updatedData.forEach((ele) => {
                            const analyticsData = [];
                            const profileCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertProfileReceived');
                            const scheduledCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsScheduled');
                            const interviewedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertsInterviewed');
                            const completedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsCompleted');
                            analyticsData.push(ele?.requestAnalytics[profileCount]);
                            analyticsData.push(ele?.requestAnalytics[scheduledCount]);
                            analyticsData.push(ele?.requestAnalytics[interviewedCount]);
                            analyticsData.push(ele?.requestAnalytics[completedCount]);

                            ele.requestAnalytics = analyticsData;
                        });

                        return updatedData;
                    });
                }

                setOpenLastPageFlag(openRequestsData.lastPage);
                setOpenPage(openRequestsData.currentPage);

            } else {
                if (openRequestsData.currentPage == 0) {
                    setClosedRequests(() => {
                        const updatedData = openRequestsData.data;
                        updatedData.forEach((ele) => {
                            const analyticsData = [];
                            const profileCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertProfileReceived');
                            const scheduledCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsScheduled');
                            const interviewedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertsInterviewed');
                            const completedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsCompleted');
                            analyticsData.push(ele?.requestAnalytics[profileCount]);
                            analyticsData.push(ele?.requestAnalytics[scheduledCount]);
                            analyticsData.push(ele?.requestAnalytics[interviewedCount]);
                            analyticsData.push(ele?.requestAnalytics[completedCount]);

                            ele.requestAnalytics = analyticsData;
                        });

                        return updatedData;
                    });
                    setClosedRequestTotalCount(openRequestsData.totalElements);
                } else {
                    setClosedRequests(() => {
                        const updatedData = closedRequests.concat(openRequestsData.data);
                        updatedData.forEach((ele) => {
                            const analyticsData = [];
                            const profileCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertProfileReceived');
                            const scheduledCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsScheduled');
                            const interviewedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'expertsInterviewed');
                            const completedCount = ele?.requestAnalytics.findIndex((e) => e?.type == 'interviewsCompleted');
                            analyticsData.push(ele?.requestAnalytics[profileCount]);
                            analyticsData.push(ele?.requestAnalytics[scheduledCount]);
                            analyticsData.push(ele?.requestAnalytics[interviewedCount]);
                            analyticsData.push(ele?.requestAnalytics[completedCount]);

                            ele.requestAnalytics = analyticsData;
                        });

                        return updatedData;
                    }
                    );
                }
                setLastPageFlag(openRequestsData.lastPage);
                setPage(openRequestsData.currentPage);
            }
        }

        if (openRequestsData?.data && openRequestsData.data[0]?.requestStatus != 'Open' && searchedString) {
            setClosedRequests([]);
            setClosedRequestTotalCount(0);
            // setPage(0);
        }

    }, [openRequestsData]);

    useEffect(() => {
        setLoading(loadingData);
    }, [loadingData]);

    useEffect(() => {
        setnoRecordFound(noRecordFoundData);
    }, [noRecordFoundData]);

    useEffect(() => {
        dispatch(getConsentAPIData());
        if (sessionStorage.getItem('hasHomeLoaded') !== 'true') {
            sessionStorage.setItem('hasHomeLoaded', 'true');
            if (getFromLocalStorageAsJSON('hasHomeLoaded') !== true) {
                sendToLocalStorage('hasHomeLoaded', true);
                if (getFromLocalStorageAsJSON('notLoadHome') !== true) {
                    globalAdobeAnalytics('EEH Home Page');
                }
            }
        }
        else {
            removeFromLocalStorage('notLoadHome');
            globalAdobeAnalytics('EEH Home Page', 'EEH Home Page');
        }

        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };

        const handleMouseDown = (event) => {
            handleClickOutside(event);
        };
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    const handleScroll = async () => {
        if (!isLastPage && activeRequest['closedreq'] === true) {
            let sortOption = selectedSortOption.includes('Newest') ? 'DESC' : 'ASC';
            dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Closed', sort: sortOption, page: page + 1 }));
        }
        if (!isOpenLastPage && activeRequest['openreq'] === true) {
            dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Open', sort: 'DESC', page: openPage + 1 }));
        }
    };

    const fetchHrId = async () => {
        const hrid = await getUserId();
        setHrid(hrid);
        dispatch(getPeopleAPIData(hrid));
        dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Closed', sort: 'DESC', page: page }));
        dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Open', sort: 'Desc', page: page }));
    }

    const handleActiveRequest = (data) => {
        switch (data) {
            case 'openreq':
                setActiveRequest({
                    openreq: true,
                    closedreq: false
                });
                initiateTabClickAnalytics('EEH Home Page', 'Open Requests', 'EEH Home Page')
                break;
            case 'closedreq':
                setActiveRequest({
                    openreq: false,
                    closedreq: true
                });
                initiateTabClickAnalytics('EEH Home Page', 'Closed Requests', 'EEH Home Page')
                break;

            default:
                setActiveRequest({
                    openreq: true,
                    closedreq: false
                });
                initiateTabClickAnalytics('EEH Home Page', 'Open Requests', 'EEH Home Page')
                break;
        }
    }

    const handleSortOptionSelection = (event) => {
        setIsPopupOpen(!isPopupOpen);
        setSelectedSortOption(event.target.outerText);
        let sortOption = event.target.outerText.includes('Newest') ? 'DESC' : 'ASC';
        setPage(0);
        dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Closed', sort: sortOption, page: 0 }));
    }

    const searchRequestHandler = (value) => {
        setSearchedString(value);
        let sortOption = selectedSortOption.includes('Newest') ? 'DESC' : 'ASC';
        if (value) {
            dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Closed', searchString: value, sort: sortOption, page: 0 }));
        } else {
            setPage(0);
            dispatch(getOpenRequestAPIData({ hrId: hrid, status: 'Closed', sort: sortOption, page: 0 }));
        }
    }

    const handleClick = () => {
        setShowGuidedTourFlag(true);
    }

    return (
        <Layout id="home" layoutClass="layout__my-navigator">
            <GuidedTour show={showGuidedTour} showTourBtn={true} tourId={tourGuideId} resetTourEvent={() => setShowGuidedTourFlag(false)} handleClick={handleClick} />
            {!hideConsentPopup && <PrivacyConsentModal open={true} closedConsentHandler={() => {setShowGuidedTourFlag(true); setHideConsentPopup(true)}} />}
            <div className="homeScreen__page">
                <>
                    <div className="homeScreen__content">
                        <div className="homeScreen__requests">
                            <div id='openRequest' className={activeRequest['openreq'] === true ? "active--request" : "open--requests inactiveopen--req"}
                                onClick={() => handleActiveRequest('openreq')}>Open Requests - {openRequestsTotalCount}</div>
                            <div id='closedRequest' className={activeRequest['closedreq'] === true ? "active--request" : "open--requests inactiveclosed--req"}
                                onClick={() => handleActiveRequest('closedreq')}>Closed Requests - {closedRequestTotalCount}</div>
                        </div>
                        {activeRequest['closedreq'] === true &&
                            <div className="homeScreen__header-actions">
                                <div className="homeScreen__searching">
                                    <SearchableField
                                        fieldId="searchField"
                                        preferredListHeight='95px'
                                        hideSearchIcon={true}
                                        isValid={true}
                                        isDisable={false}
                                        savedValue=''
                                        placeholder='Enter charge code'
                                        validateErrors={() => console.log('error')}
                                        onChargeCodeSelected={(value, field) => console.log('selected')}
                                        handleSearchResults={(value) => searchRequestHandler(value)}
                                        isSurveyForm={false}
                                    />
                                </div>
                                <div className="homeScreen__sorting">
                                    <div className="sortBy--text">Sort by </div>
                                    <div className={`sort-field ${isPopupOpen && 'field-dropdown'}`}>
                                        <input type='text' readOnly value={selectedSortOption}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setIsPopupOpen(!isPopupOpen);
                                            }}
                                        ></input>
                                        <img className="arrow-img" src={DownArrow} alt="DownArrow"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setIsPopupOpen(!isPopupOpen);
                                            }} />
                                        {
                                            isPopupOpen &&
                                            <div className="sort-popup" ref={popupRef}>
                                                <ul>
                                                    {sortData?.map((option) => (
                                                        <li key={option.index} onClick={(event) => handleSortOptionSelection(event)} className={selectedSortOption == option.name ? 'selected-list-item' : ''}>{option.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div id='main-body' className="homeScreen__body">
                        {activeRequest['openreq'] != true && closedRequests.length == 0 && searchedString ?
                            <div className='noRecFound'>
                                Currently, there are no close request for the searched charge code.
                            </div> :
                            <OpenRequests hrid={hrid} isOpenRequestFlag={activeRequest['openreq'] === true} loadingFlag={loading} noRecordFoundFlag={noRecordFound} requestData={activeRequest['openreq'] === true ? requestData : closedRequests} page={activeRequest['openreq'] === true ? openPage : page} hasMoreData={activeRequest['openreq'] === true ? !isOpenLastPage : !isLastPage} existingUser={existingUser} isConsentVisible={!hideConsentPopup} loadMoreData={handleScroll} />
                        }
                    </div>
                </>
            </div>
        </Layout>
    )
}

export const HomeScreen = (HomeContainer);