import { PROJECT_ANALYTICS_CODES } from "redux/constants";
export const name = "projectAnalyticsCodes";

// initial state
export const initialState = {
    codesLoading: true,
    codesError: false,
    errorMessage: "",
    projectAnalyticsCodesData: null,
};

// reducer selectors
export const selectors = {
    getCodesLoading: state => state[name].codesLoading,
    getCodesError: state => state[name].codesError,
    getErrorMessage: state => state[name].errorMessage,
    getProjectAnalyticsCodesData: (state) => state[name]?.projectAnalyticsCodesData ? state[name]?.projectAnalyticsCodesData : "",
};

// reducer
export function reducer(state = initialState, action) {
    switch (action.type) {
        case PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_FAILURE:
            return {
                ...state,
                codesLoading: false,
                codesError: true,
                errorMessage: action.payload,
            };
        case PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_PENDING:
            return {
                ...state,
                codesLoading: true,
                codesError: false,
                errorMessage: '',
            };
        case PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_SUCCESS:
            return {
                ...state,
                codesLoading: false,
                projectAnalyticsCodesData: action?.payload?.data,
                errorMessage: action?.payload?.errors && action?.payload?.errors[0],
            };
        case PROJECT_ANALYTICS_CODES.CLEAR_PROJECT_ANALYTICS_CODES:
            return {
                ...state,
                projectAnalyticsCodesData: [],
            }

        case PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_RESET:
            return {
                ...state,
                codesLoading: false,
                codesError: true,
                errorMessage: action.payload,
                projectAnalyticsCodesData: [],
            }
        default:
            return state;
    }
}