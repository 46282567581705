import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "containers/Layout";
import { Table, Checkbox } from 'semantic-ui-react';
import { getProjectAnalyticsAPIData, resetProjectAnalyticsAPIData } from "redux/actions/ProjectAnalytics/ProjectAnalytics_actions";
import { getProjectAnalyticsCodesAPIData } from 'redux/actions/projectAnalyticsCodes/projectAnalyticsCodes_actions';
import { selectors as projectAnalyticsSelector } from "redux/reducers/ProjectAnalytics/ProjectAnalytics_reducer";
import { selectors as projectAnalyticsCodesSelector } from "redux/reducers/projectAnalyticsCodes/projectAnalyticsCodes_reducer";
import { getUserId } from "utils/auth/auth";
import Image from "components/shared/Image";
import GreenSearchIcon from 'assets/images/greensearch_icon.svg';
import ExpandIcon from 'assets/images/expand.svg';
import InfoIcon from 'assets/images/info.svg';
import CollapseIcon from 'assets/images/collapse.svg';
import CrossIcon from "assets/images/filter-close.svg";
import ChargeCode from "assets/images/charge-code.svg";
import { PerformanceTable } from './PerformaceTable';
import { Bar } from 'react-chartjs-2';
import './ProjectAnalytics.scss';

import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);


//handle i tin case of last selected checkbox
export const ProjectAnalytics = () => {
    const dispatch = useDispatch();
    const projectAnalyticsReducer = useSelector(projectAnalyticsSelector.getProjectAnalyticsData) || null;
    const projectAnalyticsCodesReducer = useSelector(projectAnalyticsCodesSelector?.getProjectAnalyticsCodesData) || null;
    const loadingData = useSelector(projectAnalyticsSelector.getLoading);
    const suggestionBoxRef = useRef(null);
    const requestsRef = useRef(null);
    const [getHrid, setHrid] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [showSuggestion, setShowSuggestion] = useState(false);
    const [getSuggestionData, setSuggestionData] = useState([]);
    const [getNoRecord, setNoRecord] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [getPeopleAPIError, setPeopleAPIError] = useState(false);
    const [codesData, setCodesData] = useState([]);
    const [showCollapse, setShowCollapse] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);


    useEffect(() => {
        setLoading(loadingData);
    }, [loadingData]);

    useEffect(() => {
        if (projectAnalyticsReducer?.length > 0) {
            setSuggestionData(projectAnalyticsReducer);
            setNoRecord(false);
        }
        else if (projectAnalyticsReducer?.length === 0 && projectAnalyticsReducer !== null) {
            setSuggestionData([]);
            setNoRecord(true);
        }
    }, [projectAnalyticsReducer]);

    useEffect(() => {
        if (projectAnalyticsCodesReducer?.length > 0) {
            setCodesData(projectAnalyticsCodesReducer);
        }
    }, [projectAnalyticsCodesReducer]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((suggestionBoxRef?.current && !suggestionBoxRef?.current?.contains(event.target)) || requestsRef?.current && !requestsRef?.current?.contains(event.target)) {
                setShowSuggestion(false);
                setExpandedIndex(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchHrId();
    }, [history.location]);

    const fetchHrId = async () => {
        const hrid = await getUserId();
        if (hrid) {
            setPeopleAPIError(false);
        }
        else {
            setPeopleAPIError(true);
        }
        setHrid(hrid);
    }
    const handleSearchChange = (event) => {
        event.stopPropagation();
        const searchTerm = event?.target?.value;
        setSearchInput(searchTerm);
        handleSearch(searchTerm);
    }

    const handleSearch = (search) => {
        setSuggestionData([]);
        if (search?.length > 0) {
            setShowSuggestion(true);
            dispatch(getProjectAnalyticsAPIData(getHrid, search?.trim()));
        }
        else {
            setShowSuggestion(false);
            setNoRecord(false); // Reset no record flag when search input is cleared
        }
    }
    const resetSearchedData = () => {
        setSearchInput('');
        dispatch(resetProjectAnalyticsAPIData());
    }

    const handleKeyUp = (e) => {
        if (e.keyCode === 32) {
            e.target.value = e.target.value + " "
            e.stopPropagation()
        }
    }
    const handleExpandCollapse = (actionType, index) => {
        setShowCollapse(true);
        setExpandedIndex(expandedIndex === index ? null : index);
    }
    const isAnyItemExpanded = expandedIndex !== null;

    const chartDataNetwork = {
        labels: ['Alphasights', 'Intollion India', 'Coleman', 'Dialectica'],
        datasets: [
            {
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: '#A97CDD',
                borderColor: '#A97CDD',
                borderWidth: 0,
            },
        ],
    };

    const chartDataExpert = {
        labels: ['Customer', 'Former', 'Supplier', 'Competitor'],
        datasets: [
            {
                data: [15, 29, 40, 31, 56, 25, 40],
                backgroundColor: '#0977D7',
                borderColor: '#0977D7',
                borderWidth: 0,
            },
        ],
    };

    const chartOptions = {
        scales: {
            x: {
                grid: {
                    display: false, // Hide grid lines on x-axis
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Hide grid lines on x-axis
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Hide legend
            },
        },
        indexAxis: 'y',
    };
    const handleCheckBoxChange = (event, item, data) => {
        event.stopPropagation();
        const checked = item.checked;
        setSelectedItems((prevSelected) => {
            const updatedSelected = checked
                ? [...prevSelected, data]
                : prevSelected.filter((selectedItem) => selectedItem !== data);

            console.log('Updated selectedItems>>>', updatedSelected);
            console.log('previous selectedItems>>>', selectedItems);
            if (updatedSelected?.length > 0) {
                dispatch(getProjectAnalyticsCodesAPIData(updatedSelected));
            } else {
                setCodesData([]);
            }
            return updatedSelected;
        });
        if (!checked && selectedItems.length === 1) {
            setCodesData([]);
        }
    };

    const handleDivClick = (event, item) => {
        // Trigger the checkbox change manually on div click
        const isChecked = !selectedItems?.includes(item);
        handleCheckBoxChange(event, { checked: isChecked }, item);  // Call handleCheckBoxChange
    };


    return (
        <Layout id="home" layoutClass="layout__my-navigator">
            <div className="ProjectAnalytics__wrap">
                <div className="analytics__searchWrap">
                    <div className="search--text">Use the search box to add multiple charge codes to view consolidated analytics.</div>
                    <div className="search--field">
                        <div className="search__box">
                            <input type="number"
                                value={searchInput}
                                onChange={(e) =>
                                    handleSearchChange(e)
                                }
                                onKeyUp={(e) => {
                                    handleKeyUp(e);
                                }
                                }
                                onFocus={() => { setShowSuggestion(true) }}
                                placeholder="Enter Charge Codes"
                                className="search--input" />
                            {
                                searchInput?.length > 0 &&
                                <Image src={CrossIcon} alt="CrossIcon" className="cross-icon" onClick={() => {
                                    resetSearchedData();
                                }} />
                            }
                            {
                                searchInput?.length == 0 &&
                                <Image src={GreenSearchIcon} alt="search icon" className={`search--icon`}
                                />
                            }
                        </div>
                        <div className="search--overlay">
                            <Image src={InfoIcon} alt="expand icon" className={`expand--icon`} />
                            Do not share this data – unauthorized sharing may lead to privacy breaches and compliance violations.
                        </div>
                        {
                            !getPeopleAPIError ?
                                <>
                                    {

                                        showSuggestion &&
                                        <div className="suggestion__wrap" ref={suggestionBoxRef}>
                                            {
                                                !getNoRecord ?
                                                    <>
                                                        {
                                                            getSuggestionData?.map((item, index) => (
                                                                <div className="suggestion__itemWrap" key={item?.id} onClick={(e) => handleDivClick(e, item)}>
                                                                    <div className="suggestion__left">
                                                                        <Checkbox
                                                                            checked={selectedItems?.includes(item)}
                                                                            onChange={(e, data) => handleCheckBoxChange(e, data, item)}
                                                                            className='een-checkbox table--check' />
                                                                    </div>
                                                                    <div className="suggestion__right">
                                                                        <div className="right__top">
                                                                            <div className="req--id">
                                                                                Charge code :
                                                                            </div>
                                                                            <div className="req--idVal">
                                                                                {item}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }</>
                                                    :
                                                    <>
                                                        {
                                                            searchInput?.length > 0 &&
                                                            <div className="suggestion__itemWrap">
                                                                Currently, no charge codes exist.
                                                            </div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <div className="people--error left--align">
                                        Something went wrong, please refresh.
                                    </div>
                                </>
                        }
                    </div>
                </div>

                <div className={codesData?.length > 0 ? "analytics__body" : "analytics__body noHeightMargin"}>
                    {codesData?.length > 0 &&
                        <div className="body--text">Showing Analytics for </div>
                    }

                    <div className={`body__wrap ${isAnyItemExpanded ? 'hide-overflow-x' : ''}`}>
                        {
                            codesData?.map((item, index) => (
                                <><div className={expandedIndex === index ? "activebody__item body__item" : "body__item"} key={index}>
                                    <>
                                        <Image src={CrossIcon} alt="CrossIcon" className="chargecross-icon" />
                                        <div className="charge__code">
                                            <Image src={ChargeCode} alt="ChargeCode" className="item-img" />
                                            <div className="code--val">Charge Code : {item?.chargeCode}</div>
                                        </div>

                                        <div className="code--text">{item?.details || 'NA'}</div>

                                        <div className="spend--text">Actual Spend</div>
                                        <div className="code--spend">
                                            ${item?.totalCost}
                                        </div>
                                        <div className="expand--collapse" onClick={() => handleExpandCollapse('expand', index)}>
                                            {
                                                expandedIndex !== index ? 'Expand' : 'Collapse'
                                            }
                                            <Image src={expandedIndex !== index ? ExpandIcon : CollapseIcon} alt="expand icon" className={`expand--icon`}
                                            />
                                        </div>
                                    </>

                                    {
                                        expandedIndex === index &&
                                        <div className={`requests__wrap `}>
                                            <div className='request--text'>Requests</div>
                                            <div className="requests__itemsWrap">
                                                {item?.requests?.map((subItem, subIndex) => (
                                                    <div className="requests__item" key={subItem?.requestId} onClick={() => window.open(`requestDetails?interviewId=${subItem?.requestId}`, '_blank')}>
                                                <div className="request--detail">
                                                    &nbsp;{subItem?.requestTitle}
                                                </div>
                                            </div>
                                                ))}
                                        </div>
                                        </div>
                                    }
                                </div ></>
                    ))
                        }
                </div>
            </div>

            <div className="lower__body">
                <div className="spend__overView overview-block">
                    <div className="block-header">Spend Overview</div>
                    <div className="block-sub-heading">Total Estimated Spend <span className="highlighted-text">$ 600,000</span></div>
                    <div className="chart-overview">
                        <div className="spend-chart1 chart-block">
                            <div className="chart-heading1">Actual spend</div>
                            <div className="chart-heading2">By Network</div>
                            <Bar data={chartDataNetwork} options={chartOptions} />
                        </div>
                        <div className="spend-chart2 chart-block">
                            <div className="chart-heading1">Actual spend</div>
                            <div className="chart-heading2">by Expert Type</div>
                            <Bar data={chartDataExpert} options={chartOptions} />
                        </div>
                    </div>
                </div>
                <div className="performance__overview overview-block">
                    <div className="block-header">Performance Overview</div>
                    <div className="block-sub-heading ">Vendor Performance</div>
                    <div className="vendortable--text">Percentage of total project engagements facilitated by each expert network.</div>
                    <PerformanceTable />
                </div>
            </div>
        </div>
        </Layout >
    )
}