// developer utils we can run in dev console
import { getOktaExpirations } from 'utils/auth/auth';

export default () => {
    /**
     * logs out the map of all variables from `process.env`
     */
    // window.bcgEnvVars = () => {
    //     Object.keys(process.env).map((key) => (
    //         // eslint-disable-next-line no-console
    //         console.log(key, process.env[key])
    //     ));
    // };

    /**
     * logs out the expiration times for the okta access and id tokens
     */
    window.bcgTokenExpiration = () => {
        const [oktaAccessExpiration, oktaIdExpiration] = getOktaExpirations();
    };
};
