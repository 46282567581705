import { PROJECT_ANALYTICS_CODES } from 'redux/constants';

//  project analytics codes actions
export const getProjectAnalyticsCodesAPIData = (chargeCode) => ({
    type: PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_DATA,
    payload: {
        chargeCodes: chargeCode
    }
});

export const getProjectAnalyticsCodesAPIDataPending = () => ({
    type: PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_PENDING
});

export const getProjectAnalyticsCodesAPIDataSuccess = (payload) => ({
    type: PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_SUCCESS,
    payload
});

export const getProjectAnalyticsCodesAPIDataFailure = (error) => ({
    type: PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_FAILURE,
    payload: { error: error?.toString() }
});


export const resetProjectAnalyticsCodesAPIData = () => ({
    type: PROJECT_ANALYTICS_CODES.API_PROJECT_ANALYTICS_CODES_RESULTS_RESET
})