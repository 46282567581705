import React from 'react';
import './FloatingButton.scss';
import TourOff from 'assets/images/tour-off.svg';

const FloatingButton = ({ onClick }) => {
  return (
    <button className="floating-button" onClick={onClick}>
      <span>Guided Tour</span>
    </button>
  );
};

export default FloatingButton;